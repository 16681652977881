export const GameOptions = {
    bgColors: [0x62bd18, 0xff5300, 0xd21034, 0xff475c, 0x8f16b2, 0x588c7e, 0x8c4646],
    holeWidthRange: [80, 260],
    wallRange: [10, 50],
    growTime: 1500,
    localStorageName: 'squaregamephaser3',
    platformSpeedRange: [300, 300],
    spawnRange: [80, 300],
    platformSizeRange: [90, 300],
    platformHeightRange: [-5, 5],
    platformHeighScale: 20,
    platformVerticalLimit: [0.4, 0.8],
    playerGravity: 900,
    jumpForce: 400,
    playerStartPosition: 200,
    jumps: 2,
    coinPercent: 50,
    bookcase1Width: 130,
    picHeight: 350,
    customPicsFrameWidth: 20,
    proLevelScore: 20,
    masterLevelScore: 60,
    speedSampling: 2000,
    RaceDistance: 400,
    scrolForNextLevel: 20000
}

export const GameConsts = {
    blackColor: '#000000',
    blueColor: '#1563E5',
    yellowColor: '#F8E71C',
    lightBlueColor: '#4A90E2',
    whiteColor: '#ffff',
    turquoiseColor: '#80f1ec',
}