import { ResourcesService, Resources } from '../services/resources.service'
import TextureKeys from './textureKeys' 
import TweenHelper from './tweenHelper' 
import { GameOptions, GameConsts } from './gameOptions';
import DisplayHelper from './displayHelper';

let isFirst = true;
export class PreloadAssets extends Phaser.Scene {
    
    private loadFinished = false;
    private loadComplete = false;
    private loadText: Phaser.GameObjects.Text;
    private instructionsText: Phaser.GameObjects.Text;
    private logo: Phaser.GameObjects.Image
    private rotate: Phaser.GameObjects.Image
    // private instructions: Phaser.GameObjects.Rectangle
    private instructions: Phaser.GameObjects.Image
    private startButtonPosW = 0;
    private startButtonPosH = 0;
    private modeButtonPosW = 0;
    private modeButtonPosH = 0;
    private helpButtonPosW = 0;
    private helpButtonPosH = 0;
    private nameInputPosW = 0;
    private nameInputPosH = 0;
    private instructionsPosW = 0;
    private instructionsPosH = 0;
    private resource;
    private nameInput:any;
    private openGammataPortal = false;
    private homeButtonX = 0;
    private homeButtonY = 0;


    constructor(private resourcesService: ResourcesService) {
        super({
            key: 'PreloadAssets'
        });
    }

    preload() {
        console.log('preload - before static images');
        this.load.html('form', 'assets/form.html');

        // this.load.image('bg-blue', 'assets/background-onlycolor.svg');
        // this.load.image('logo', 'assets/gammatalogo-low.jpg');
        // this.load.image('logo', 'assets/bg_starburst_aqua_iphone.png');
        this.load.image('logo', 'assets/logo-transparent.png');
        this.load.image('bg-blue', 'assets/bg_starburst_blue_ipad@2x.png');
        this.load.image('bg-santa', 'assets/santa-background.jpg');
        
        
        this.load.image('boy_opening', 'assets/boy_for_opening.png');
        this.load.image('girl_opening', 'assets/girl_for_opening.png');
        this.load.image('santa_opening', 'assets/santa_opening.png');
        this.load.image('rotatePhone', 'assets/rotetePhone.png');
        this.load.image(TextureKeys.HomeButton, 'assets/home.png');
        
        var self = this;
        this.load.once(Phaser.Loader.Events.COMPLETE, async () => {
            console.log('create start');
            const width = this.scale.width;
            const height = this.scale.height;
            self.startButtonPosW = this.scale.width - 180;
            self.startButtonPosH = this.scale.height - 70;
            self.modeButtonPosW = 180;
            self.modeButtonPosH = this.scale.height - 70;
            self.helpButtonPosW = 80;
            self.helpButtonPosH = this.scale.height - 70;
            self.nameInputPosW = this.scale.width/2;
            self.nameInputPosH = this.scale.height/2 + 60;
            self.instructionsPosW = this.scale.width/2;
            self.instructionsPosH = this.scale.height/2 - 30;
            self.homeButtonX = this.scale.width * 0.5;
            self.homeButtonY = 280;
    
            self.add.image(
                width/2,
                height/2,
                'bg-blue'
            )
            .setOrigin(0.5, 0.5)
            .setScale(1.4)
            .setDepth(-3);
    
            self.logo = this.add.image(
                width/2,
                height-70,
                'logo'
            )
            .setOrigin(0.5, 0.5)
            .setScale(0.25);
    
            self.loadText = self.add.text( width * 0.5,  height * 0.5, 'Loading...', {
                fontSize: '70px',
                color: GameConsts.blackColor,
                shadow: { fill: true, blur: 0, offsetY: 0 },
                padding: { left: 15, right: 15, top: 10, bottom: 10 },
                strokeThickness: 20
            })
            .setOrigin(0.5);
    
            TweenHelper.flashElement(this, self.loadText, true, 'Linear', 1500, 100);

            self.rotate = self.add.image(
                width * 0.85,
                height/2 - 200,
                'rotatePhone'
            )
            .setOrigin(0.5, 0.5)
            .setScale(0.3)
            .setVisible(false)
            .setDepth(30);

            if(window.innerHeight > window.innerWidth){
                self.rotate.setVisible(true);
                TweenHelper.flashElement(self, self.rotate, true, 'Linear', 800, 80);
            }

            window.addEventListener("orientationchange", function() {
                if(window.innerHeight < window.innerWidth) {
                    self.rotate.setVisible(true);
                }
                else {
                    self.rotate.setVisible(false);
                }  
              }, false);

            console.log('preload - finish initial load');

            await self.createPriv();
        });
        this.load.start();
	}

    continueLoad() {
        this.load.image(TextureKeys.Background, 'assets/bg_repeat_340x640.png');
        this.load.image(TextureKeys.MouseHole, 'assets/object_mousehole.png');
        this.load.image(TextureKeys.Window1, 'assets/object_window1.png');
        this.load.image(TextureKeys.Window2, 'assets/object_window2.png');
        this.load.image(TextureKeys.PlayButton, 'assets/play.png');
        this.load.image(TextureKeys.RaceModeButton, 'assets/round.png');
        this.load.image(TextureKeys.HelpButton, 'assets/help.png');
        this.load.image('instructions', 'assets/1800px-by-1536px.png');
        this.load.image('downarrow', 'assets/downarrow.png');
                
        // this.load.image(TextureKeys.Bookcase1, 'assets/object_bookcase1.png');
        this.load.image(TextureKeys.Bookcase1, 'assets/green_three_draw.png');
        
        // this.load.image(TextureKeys.Bookcase2, 'assets/object_bookcase2.png');
        this.load.image(TextureKeys.Bookcase2, 'assets/blue_3_shelves.png');
        
        this.load.image(TextureKeys.Coin, 'assets/object_coin.png');
        
        this.load.image(TextureKeys.Lego2, 'assets/lego_2.png');
        
        // this.load.image(TextureKeys.Table, 'assets/home_table.png');
        this.load.image(TextureKeys.Table, 'assets/light_wood_table.png');
        
        this.load.image(TextureKeys.Cactus, 'assets/house_plant_example_03.png');
        this.load.image(TextureKeys.Book, 'assets/blue_perspective_down.png');
        this.load.image(TextureKeys.ParticleStar, 'assets/star_01.png');
        this.load.image(TextureKeys.PhotoFrame, 'assets/Frame_charcoalBG.png');
        this.load.image(TextureKeys.Monitor, 'assets/computer_monitor.png');
        this.load.image(TextureKeys.MonitorDesk, 'assets/yellow_desk.png');
        this.load.image(TextureKeys.SNOW, 'assets/snow1.png');
        
        this.load.audio("flap", ["assets/mixkit-golf-shot-breaking-the-air-2124.wav"]);
        this.load.audio("hit", ["assets/mixkit-impact-of-a-strong-punch-2155.mp3"]);
        this.load.audio("score", ["assets/mixkit-quick-win-video-game-notification-269.wav"]);
        this.load.audio("gamerover", ["assets/gameover.mp3"]);
        
        this.load.atlas(
                        'coins',
                        'assets/coins-spritesheet.png',
                        'assets/coins-spritesheet.json');     

        this.loadCharacterImages(this.resource.chosenCharacter);
        
        var gamePics = this.resource.blobs;
        var customImagesList = [];

        gamePics.forEach((element, index) => { 
            var picName = 'pic'+(index).toString();
            if (element.toString().indexOf('startupimage.png') >= 0)
            {
                this.load.image('startupimage', element);   
            }
            else if (element.toString().indexOf('endimage.png') >= 0)
            {
                this.load.image('endimage', element);   
            } 
            else if (element.toString().indexOf('boy') >= 0)
            {

            }
            else if (element.toString().indexOf('girl') >= 0)
            {

            }
            else
            {
                this.load.image(picName, element);
                customImagesList.push(picName);   
            }
            console.log('loding pic'+(index).toString() + ' ' + element);
        });

        var self = this;
        this.load.once(Phaser.Loader.Events.COMPLETE, () => {
            this.loadCharacterAnimation(this.resource.chosenCharacter);

            this.anims.create({
                key: 'coins', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'coins', frame: 'gold_coin_round_star_1@2x.png' },
                    { key: 'coins', frame: 'gold_coin_round_star_2@2x.png' },
                    { key: 'coins', frame: 'gold_coin_round_star_3@2x.png' },
                    { key: 'coins', frame: 'gold_coin_round_star_4@2x.png' },
                    { key: 'coins', frame: 'gold_coin_round_star_5@2x.png' },
                    { key: 'coins', frame: 'gold_coin_round_star_6@2x.png' },
                ],
                frameRate: 15,
                repeat: -1 // -1 to loop forever
            });
        
            self.loadComplete = true;
        });
        this.load.start();

        this.resourcesService.setCustomImagesList(customImagesList);
        this.resourcesService.setGameName(this.resource.gameName);
        this.resourcesService.setGemsText(this.resource.gemsText);
        this.resourcesService.setScoreMessageText(this.resource.scoreMessageText);
        this.resourcesService.setGameEndText(this.resource.gameEndText);
        this.resourcesService.setChosenCharacter(this.resource.chosenCharacter);
        this.resourcesService.setGameRecord(this.resource.gameRecord);
      
        this.loadFinished = true;
        console.log('continueLoad - end');     
    }


	async createPriv() {
        console.log('create start');

        var gameId = this.resourcesService.getQueryString('gameid');

        if (!gameId) {
            this.loadText.setVisible(false);
            this.add.image(
                this.homeButtonX,
                this.homeButtonY,
                TextureKeys.HomeButton
            ).setScale(0.12)
            .setOrigin(0.5, 0.5);
        
            this.add.text(this.homeButtonX,this.homeButtonY - 150, "Game not configured yet", {
                fontFamily: 'Luckiest Guy',
                fontSize: '48px',
                color: GameConsts.whiteColor,
                fontStyle: 'normal',
                strokeThickness: 9,
    			stroke: GameConsts.blackColor,
                shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
            })
            .setOrigin(0.5, 0.5);

            this.add.text(this.homeButtonX,this.homeButtonY - 90, "click to create your own game", {
                fontFamily: 'Luckiest Guy',
                fontSize: '48px',
                color: GameConsts.whiteColor,
                fontStyle: 'normal',
                strokeThickness: 9,
    			stroke: GameConsts.blackColor,
                shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
            })
            .setOrigin(0.5, 0.5);

            this.openGammataPortal = true;
            this.rotate.setVisible(false);

            this.sleep(200).then(() => {
                this.checkIfToNavigateToPortal();
            });
        }

        if (gameId) {
            this.resource = await this.resourcesService.getResources(this.resourcesService.getQueryString('gameid'));

            // xxx
            // this.resource.chosenCharacter = 'santa';
            
            if (this.resourcesService.getQueryString('deleteLocalVars') == 'true')
            {
                localStorage.setItem('gammata player name', '');
                localStorage.setItem('raceModeTried', '');
            }
    
            this.continueLoad();
    
            const width = this.scale.width;
            const height = this.scale.height;
    
            this.sleep(200).then(() => {
                this.checkIfToStart();        
            });
    
            console.log('create end');    
        }        
	}

    checkIfToNavigateToPortal() {
        if (this.openGammataPortal) {
            console.log('portal');
            if (this.input.activePointer.isDown)
            {
                console.log('portal - down');
                if ((Phaser.Math.Difference(this.input.activePointer.downX,  this.homeButtonX) < 50 &&  Phaser.Math.Difference(this.input.activePointer.downY, this.homeButtonY) < 50))
                {
                    this.openGammataPortal = false;
                    window.location.assign('https://setup.gammata.net')
                }
            } else {
                this.sleep(200).then(() => {
                    this.checkIfToNavigateToPortal();
                });
            }
        }   
    }

    checkIfToStart() {
        if (this.loadFinished && this.loadComplete)
        { 
            if (isFirst) {
                isFirst = false;
                this.addCustomImage();
                this.takeSnapshot();
                
                console.log('image added');
            }   
                
            if ((Phaser.Math.Difference(this.input.activePointer.downX, this.startButtonPosW ) < 100 &&  Phaser.Math.Difference(this.input.activePointer.downY, this.startButtonPosH) < 100))
            {
                if (this.nameInput) {
                    let name = this.nameInput.getChildByName("name");
                    if(name.value != "") {
                        localStorage.setItem('gammata player name', name.value);
                    }    
                }

                this.resourcesService.setPlayerName(localStorage.getItem('gammata player name'));
                if (this.resourcesService.getGameMode() == 'RaceMode')
                {
                    localStorage.setItem('raceModeTried', 'yes');
                }

                console.log('image added - stoping scene');
                this.scene.stop('PreloadAssets');
                console.log('image added - starting new scene');
                this.scene.start('PlayGame');
            }  
            else
            {
                this.sleep(200).then(() => {
                    this.checkIfToStart();
                });        
            }  
        }
        else
        {
            this.sleep(200).then(() => {
                this.checkIfToStart();
            });
        }
    }

    addCustomImage()
    {
        const width = this.scale.width;
        const height = this.scale.height;

        this.loadText.visible = false;

        if (this.resource.chosenCharacter == 'santa') {

            this.add.image(
                width/2,
                height/2,
                'bg-santa'
            )
            .setOrigin(0.5, 0.5)
            .setScale(1.4)
            .setDepth(-1);

            this.add.image(
                130,
                height*0.7,
                'santa_opening'
            ).setOrigin(0.5, 1)
            .setScale(0.4)

            this.add.image(
                width -125,
                height*0.7,
                'santa_opening'
            ).setOrigin(0.5, 1)
            .setScale(0.4)
            .setFlipX(true)
   
            this.emitSnow();
        } else {
            this.add.image(
                110,
                height*0.8,
                'boy_opening'
            ).setOrigin(0.5, 1)
            .setScale(0.55)
    
            this.add.image(
                width -95,
                height*0.8,
                'girl_opening'
            ).setOrigin(0.6, 1)
            .setScale(0.71)
            .setFlipX(true)    
        }

        const startTextX = width * 0.5;
        const startTextY = height * 0.5;

        var startText = this.add.text(startTextX, startTextY, this.resourcesService.getGameName(), {
            fontFamily: 'Luckiest Guy',
			fontSize: '64px',
			color: GameConsts.yellowColor,
			fontStyle: 'normal',
			stroke: GameConsts.blackColor,
			strokeThickness: 9,
            shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
		})
        .setOrigin(0.5);

        console.log('pic for start screen '+ this.resourcesService.getCustomImagesList()[0]);
        var currPic = this.add.image(
            width * 0.5,
            height * 0.45,
            'startupimage'
        ).setOrigin(0.5, 1);       

        var scale =  height* 0.43/currPic.height;
        currPic.setScale(scale);

        const frameXPos = currPic.x;
        const frameYPos = currPic.y+5;

        var frame = this.add.sprite(frameXPos, frameYPos, TextureKeys.PhotoFrame);

        currPic.setDepth(8);

        frame.displayWidth = currPic.displayWidth+10;
        frame.displayHeight = currPic.displayHeight+10;

        frame.setOrigin(0.5, 1);
        frame.setDepth(7);

        // var r1 = this.add.rectangle(currPic.x, currPic.y, currPic.displayWidth, currPic.displayHeight).setOrigin(0.5, 1);
    }

    // sleep time expects milliseconds
    sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    private takeSnapshot ()
    {
        var self = this;
        
        this.game.renderer.snapshot(function (image) {          
            // console.log('img = ' + (image as HTMLImageElement).currentSrc);
            console.log('snapshot taken');
            self.resourcesService.setSnapshot((image as HTMLImageElement).currentSrc);

            var button = self.add.image(
                self.startButtonPosW,
                self.startButtonPosH,
                TextureKeys.PlayButton
            ).setScale(0.16)
            .on('pointerdown', () => raceButton.setScale( 0.1 ))
            .on('pointerup', () => raceButton.setScale( 0.08 ))
            .setOrigin(0.5, 0.5);

            // TweenHelper.flashElement(self, button, true, 'Linear', 1500, 100);

            const raceButtonText1X = self.modeButtonPosW;
            const raceButtonText1Y = self.modeButtonPosH - 15;
            const raceButtonText2X = self.modeButtonPosW;
            const raceButtonText2Y = self.modeButtonPosH + 15;


            var raceButtonText1 = self.add.text(raceButtonText1X, raceButtonText1Y, 'Race', {
                fontSize: '40px',
                color: GameConsts.blackColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 1,
                shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
            })
            .setOrigin(0.5, 0.5)
            .setDepth(10);

            var raceButtonText2 = self.add.text(raceButtonText2X, raceButtonText2Y, 'mode', {
                fontSize: '40px',
                color: GameConsts.blackColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 1,
                shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
            })
            .setOrigin(0.5, 0.5)
            .setDepth(10);

            const raceButtonX = self.modeButtonPosW;
            const raceButtonY = self.startButtonPosH;

            const raceButton = self.add.sprite(raceButtonX, raceButtonY, TextureKeys.RaceModeButton)
            .setInteractive()
            .setOrigin(0.5, 0.5)
            .setScale(0.16)
            .on('pointerdown', () => {
                if (self.resourcesService.getGameMode() == 'RaceMode') { 
                    DisplayHelper.toggleButtonColor(0xffff, 'black', 'black', raceButton, raceButtonText1, raceButtonText2);
                    self.resourcesService.setGameMode('RegularMode');
                } else {
                    DisplayHelper.toggleButtonColor(0xFF0000, 'white', 'white', raceButton, raceButtonText1, raceButtonText2);
                    self.resourcesService.setGameMode('RaceMode');
                }
            });

            const arrow1X = self.modeButtonPosW;
            const arrow1Y = self.startButtonPosH - 83;
            const arrow2X = self.modeButtonPosW - 100;
            const arrow2Y = self.startButtonPosH - 83;
            const tryItText1X = self.modeButtonPosW;
            const tryItText1Y = self.startButtonPosH - 170;
            const tryItText2X = self.modeButtonPosW;
            const tryItText2Y = self.startButtonPosH - 130;

            if (localStorage.getItem('raceModeTried') == null || localStorage.getItem('raceModeTried') == '') {
                var arrow1 = self.add.image(arrow1X, arrow1Y, 'downarrow')
                .setScale(0.10)
                .setOrigin(0.5, 0.5)
                .setDepth(10);

                var arrow2 = self.add.image(arrow2X, arrow2Y, 'downarrow')
                .setScale(0.10)
                .setOrigin(0.5, 0.5)
                .setDepth(10);

                var tryItText = self.add.text(tryItText1X, tryItText1Y, 'Try race mode', {
                    fontFamily: 'Luckiest Guy',
                    fontSize: '40px',
                    color: GameConsts.turquoiseColor,
                    fontStyle: 'normal',
                    stroke: GameConsts.blackColor,
                    // stroke: '#FFFFFF',
                    strokeThickness: 9,
                    shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true },
                    align: 'center',
                    wordWrap: { width: 530 }
                })
                .setOrigin(0.5, 0.5)
                .setDepth(10);

                var tryItText = self.add.text(tryItText2X, tryItText2Y, '(but read the instructions first)', {
                    fontFamily: 'Luckiest Guy',
                    fontSize: '20px',
                    color: GameConsts.turquoiseColor,
                    fontStyle: 'normal',
                    stroke: GameConsts.blackColor,
                    strokeThickness: 9,
                    shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true },
                    align: 'center',
                    wordWrap: { width: 530 }
                })
                .setOrigin(0.5, 0.5)
                .setDepth(10);

                TweenHelper.flashElement(self, arrow1, true, 'Linear', 800, 80);
                TweenHelper.flashElement(self, arrow2, true, 'Linear', 800, 80);
            }

            if (localStorage.getItem('gammata player name') == null || localStorage.getItem('gammata player name') == '') {
                self.nameInput = self.add.dom(self.nameInputPosW, self.nameInputPosH).createFromCache("form");    
            }

            const helpButton = self.add.sprite(self.helpButtonPosW, self.helpButtonPosH, TextureKeys.HelpButton)
            .setInteractive()
            .setOrigin(0.5, 0.5)
            .setScale(0.08)
            .on('pointerdown', () => {
                if (self.instructions) {
                    self.instructions.destroy();
                    self.instructions = null;
                    self.instructionsText.destroy();
                    self.instructionsText = null;
                    if (self.nameInput) {
                        self.nameInput.setVisible(true);
                    }

                    helpButton.setTint(0xffff);
                } else {
                    if (self.nameInput) {
                        self.nameInput.setVisible(false);
                    }

                    helpButton.setTint(0xFFD700);

                    self.instructions = self.add.image(self.instructionsPosW, self.instructionsPosH, 'instructions')
                    .setScale(0.35)
                    .setOrigin(0.5, 0.5)
                    .setDepth(10);

                    self.instructionsText = self.add.text(self.instructionsPosW, self.instructionsPosH, 'In race mode you should run and jump over obstecales and finish as fast as you can a 400m race.\n\nYou will see another player image (shadow) which represents the best time you or another player of your custom game made.\n\nRace the shadow to become the new record holder!', {
                        fontSize: '30px',
                        color: GameConsts.blackColor,
                        fontStyle: 'normal',
                        stroke: GameConsts.blackColor,
                        strokeThickness: 1,
                        wordWrap: { width: 530 }
                    })
                    .setOrigin(0.5, 0.5)
                    .setDepth(10);
        
                }
            });    
        });
    }

    private loadCharacterImages (character: string)
    {
        if (character == 'girl')
        {
            const girlPng = this.resource.blobs.find(blob => blob.includes("girl.png"));
            const girlJson = this.resource.blobs.find(blob => blob.includes("girl.json"));
            this.load.atlas(
                'girl-anim',
                girlPng,
                girlJson);                             
        } else if (character == 'boy') {
            const boyPng = this.resource.blobs.find(blob => blob.includes("boy.png"));
            const boyJson = this.resource.blobs.find(blob => blob.includes("boy.json"));
            this.load.atlas(
                'boy-anim',
                boyPng,
                boyJson);
        } else if (character == 'santa') {
            this.load.atlas(
                            'santa',
                            'assets/santa-spritesheet.png',
                            'assets/santa-spritesheet.json');
        }
    }

    private loadCharacterAnimation (character: string)
    {
        if (character == 'girl')
        {
            this.anims.create({
                key: 'girl-dizzy', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'girl-anim', frame: 'Dizzy_000.png' },
                    { key: 'girl-anim', frame: 'Dizzy_001.png' },
                    { key: 'girl-anim', frame: 'Dizzy_002.png' },
                ],
                frameRate: 10,
                repeat:-1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'girl-idle', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'girl-anim', frame: 'Idle_000.png' },
                    { key: 'girl-anim', frame: 'Idle_001.png' },
                    { key: 'girl-anim', frame: 'Idle_002.png' },
                    { key: 'girl-anim', frame: 'Idle_003.png' },
                    { key: 'girl-anim', frame: 'Idle_004.png' },
                    { key: 'girl-anim', frame: 'Idle_005.png' },
                    { key: 'girl-anim', frame: 'Idle_006.png' },
                    { key: 'girl-anim', frame: 'Idle_007.png' },
                    { key: 'girl-anim', frame: 'Idle_008.png' },
                    { key: 'girl-anim', frame: 'Idle_009.png' },
                    { key: 'girl-anim', frame: 'Idle_010.png' },
                    { key: 'girl-anim', frame: 'Idle_011.png' },
                ],
                frameRate: 10,
                repeat:-1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'girl-jump', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'girl-anim', frame: 'Jump_000.png' },
                    { key: 'girl-anim', frame: 'Jump_001.png' },
                    { key: 'girl-anim', frame: 'Jump_002.png' },
                    { key: 'girl-anim', frame: 'Jump_003.png' },
                    { key: 'girl-anim', frame: 'Jump_004.png' },
                    { key: 'girl-anim', frame: 'Jump_005.png' },
                    { key: 'girl-anim', frame: 'Jump_006.png' },
                    { key: 'girl-anim', frame: 'Jump_007.png' },
                ],
                frameRate: 6,
                repeat:-1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'girl-run', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'girl-anim', frame: 'Run_000.png' },
                    { key: 'girl-anim', frame: 'Run_001.png' },
                    { key: 'girl-anim', frame: 'Run_002.png' },
                    { key: 'girl-anim', frame: 'Run_003.png' },
                    { key: 'girl-anim', frame: 'Run_004.png' },
                    { key: 'girl-anim', frame: 'Run_005.png' },
                    { key: 'girl-anim', frame: 'Run_006.png' },
                    { key: 'girl-anim', frame: 'Run_007.png' },
                ],
                frameRate: 24,
                repeat:-1 // -1 to loop forever
            });    
        } else if (character == 'boy') {
            this.anims.create({
                key: 'boy-run', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'boy-anim', frame: 'Run_000.png' },
                    { key: 'boy-anim', frame: 'Run_001.png' },
                    { key: 'boy-anim', frame: 'Run_002.png' },
                    { key: 'boy-anim', frame: 'Run_003.png' },
                    { key: 'boy-anim', frame: 'Run_004.png' },
                    { key: 'boy-anim', frame: 'Run_005.png' },
                    { key: 'boy-anim', frame: 'Run_006.png' },
                    { key: 'boy-anim', frame: 'Run_007.png' },
                    { key: 'boy-anim', frame: 'Run_008.png' },
                    { key: 'boy-anim', frame: 'Run_009.png' },
                ],
                frameRate: 24,
                repeat: -1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'boy-jump', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'boy-anim', frame: 'Jump_000.png' },
                    { key: 'boy-anim', frame: 'Jump_001.png' },
                    { key: 'boy-anim', frame: 'Jump_002.png' },
                    { key: 'boy-anim', frame: 'Jump_003.png' },
                    { key: 'boy-anim', frame: 'Jump_004.png' },
                    { key: 'boy-anim', frame: 'Jump_005.png' },
                ],
                frameRate: 10,
                repeat: -1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'boy-idle', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'boy-anim', frame: 'Idle_000.png' },
                    { key: 'boy-anim', frame: 'Idle_001.png' },
                    { key: 'boy-anim', frame: 'Idle_002.png' },
                    { key: 'boy-anim', frame: 'Idle_003.png' },
                    { key: 'boy-anim', frame: 'Idle_004.png' },
                    { key: 'boy-anim', frame: 'Idle_005.png' },
                    { key: 'boy-anim', frame: 'Idle_006.png' },
                    { key: 'boy-anim', frame: 'Idle_007.png' },
                    { key: 'boy-anim', frame: 'Idle_008.png' },
                    { key: 'boy-anim', frame: 'Idle_009.png' },
                    { key: 'boy-anim', frame: 'Idle_010.png' },
                    { key: 'boy-anim', frame: 'Idle_011.png' },
                ],
                frameRate: 12,
                repeat: 0 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'boy-dizzy', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'boy-anim', frame: 'Dizzy_000.png' },
                    { key: 'boy-anim', frame: 'Dizzy_001.png' },
                    { key: 'boy-anim', frame: 'Dizzy_002.png' },
                    { key: 'boy-anim', frame: 'Dizzy_003.png' },
                    //{ key: 'boy', frame: 'Die_002.png' },
                ],
                frameRate: 7,
                repeat: 0 // -1 to loop forever
            });    
        } else if (character == 'santa') {
            this.anims.create({
                key: 'santa-run', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'santa', frame: 'Run (1).png' },
                    { key: 'santa', frame: 'Run (2).png' },
                    { key: 'santa', frame: 'Run (3).png' },
                    { key: 'santa', frame: 'Run (4).png' },
                    { key: 'santa', frame: 'Run (5).png' },
                    { key: 'santa', frame: 'Run (6).png' },
                    { key: 'santa', frame: 'Run (7).png' },
                    { key: 'santa', frame: 'Run (8).png' },
                    { key: 'santa', frame: 'Run (9).png' },
                ],
                frameRate: 24,
                repeat: -1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'santa-jump', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'santa', frame: 'Jump (1).png' },
                    { key: 'santa', frame: 'Jump (2).png' },
                    { key: 'santa', frame: 'Jump (3).png' },
                    { key: 'santa', frame: 'Jump (4).png' },
                    { key: 'santa', frame: 'Jump (5).png' },
                    { key: 'santa', frame: 'Jump (6).png' },
                    // { key: 'santa', frame: 'Jump (7).png' },
                    // { key: 'santa', frame: 'Jump (8).png' },
                    // { key: 'santa', frame: 'Jump (9).png' },
                    // { key: 'santa', frame: 'Jump (10).png' },
                    // { key: 'santa', frame: 'Jump (11)).png' },
                    // { key: 'santa', frame: 'Jump (12).png' },
                    // { key: 'santa', frame: 'Jump (13).png' },
                    // { key: 'santa', frame: 'Jump (14).png' },
                    // { key: 'santa', frame: 'Jump (15).png' },
                    // { key: 'santa', frame: 'Jump (16).png' },
                ],
                frameRate: 10,
                repeat: -1 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'santa-idle', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'santa', frame: 'Idle (1).png' },
                    { key: 'santa', frame: 'Idle (2).png' },
                    { key: 'santa', frame: 'Idle (3).png' },
                    { key: 'santa', frame: 'Idle (4).png' },
                    { key: 'santa', frame: 'Idle (5).png' },
                    { key: 'santa', frame: 'Idle (6).png' },
                    { key: 'santa', frame: 'Idle (7).png' },
                    { key: 'santa', frame: 'Idle (8).png' },
                    { key: 'santa', frame: 'Idle (9).png' },
                    { key: 'santa', frame: 'Idle (10).png' },
                    { key: 'santa', frame: 'Idle (11).png' },
                    { key: 'santa', frame: 'Idle (12).png' },
                    { key: 'santa', frame: 'Idle (13).png' },
                    { key: 'santa', frame: 'Idle (14).png' },
                    { key: 'santa', frame: 'Idle (15).png' },
                    { key: 'santa', frame: 'Idle (16).png' },
                ],
                frameRate: 16,
                repeat: 0 // -1 to loop forever
            });
    
            this.anims.create({
                key: 'santa-dizzy', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'santa', frame: 'Dead (1).png' },
                    { key: 'santa', frame: 'Dead (2).png' },
                    { key: 'santa', frame: 'Dead (3).png' },
                    { key: 'santa', frame: 'Dead (4).png' },
                    { key: 'santa', frame: 'Dead (5).png' },
                    { key: 'santa', frame: 'Dead (6).png' },
                    { key: 'santa', frame: 'Dead (7).png' },
                    { key: 'santa', frame: 'Dead (8).png' },
                    { key: 'santa', frame: 'Dead (9).png' },
                    { key: 'santa', frame: 'Dead (10).png' },
                    { key: 'santa', frame: 'Dead (11).png' },
                    { key: 'santa', frame: 'Dead (12).png' },
                    { key: 'santa', frame: 'Dead (13).png' },
                    { key: 'santa', frame: 'Dead (14).png' },
                    { key: 'santa', frame: 'Dead (15).png' },
                    { key: 'santa', frame: 'Dead (16).png' },
                    { key: 'santa', frame: 'Dead (17).png' },
                ],
                frameRate: 17,
                repeat: 0 // -1 to loop forever
            });    
        }
    }

    private emitSnow() {
        const particles = this.add.particles(TextureKeys.SNOW);
        particles.setDepth(1000);
		particles.createEmitter({
			x: 0,
			y: 0,
			// emitZone
			emitZone: {
				source: new Phaser.Geom.Rectangle(-this.scale.width * 3, 0, this.scale.width * 7, 100),
				type: 'random',
				quantity: 30
			},
			speedY: { min: 50, max: 70 },
			speedX: { min: -20, max: 20 },
			accelerationY: { random: [10, 15] },
			// lifespan
			lifespan: { min: 8000, max: 10000 },
			scale: { random: [0.025, 0.075] },
			alpha: { random: [0.1, 0.8] },
			gravityY: 10,
			frequency: 10,
			blendMode: 'ADD',
		})
      }
}
