import Phaser from 'phaser'
import TextureKeys from './textureKeys';

export enum MouseState
{
    Running,
    Killed,
    Dead
}

export enum CharacterType
{
    Boy = 'boy',
    Girl = 'girl',
    Santa = 'santa'
}

const lowVelocity = 350;
const highVelocity = 450;
const jumpVelocity = -360;
const blockedBodyYValue = 200;
const minimalVelocityForMoveX = 40;
const velocityOfDeathX = 30;

export class Player extends Phaser.GameObjects.Container
{
    private flames: Phaser.GameObjects.Sprite;
    public player: Phaser.GameObjects.Sprite;
    private cursors: Phaser.Types.Input.Keyboard.CursorKeys;
    private mouseState = MouseState.Running;
    private playerJumps = 0;
    private canCheckClick = true;
    private flapSound: Phaser.Sound.BaseSound;
    private unlimitedJumps = false;
    private characterType: string;

    constructor(scene: Phaser.Scene, x: number, y: number, characterType: string)
    {        
        super(scene, x, y);

        if (characterType != 'girl' && characterType != 'boy' && characterType != 'santa')
        {
            characterType = 'boy';
        }

        var charAdjustment = 1;
        var charAdjustmentBody = 1;
        var charAdjustmentBodyWidth = 1;
        if (characterType == 'girl')
        {
            console.log("adjusting for girl char");
            charAdjustment = 1.1;
            charAdjustmentBody = 1.15;
        } else  if (characterType == 'santa') {
            charAdjustment = 0.9;
            charAdjustmentBody = 0.9;
            charAdjustmentBodyWidth = 0.7;
        } else  if (characterType == 'boy') {
            charAdjustment = 1.0;
            charAdjustmentBody = 0.9;
            charAdjustmentBodyWidth = 0.7;
        }

        this.cursors = scene.input.keyboard.createCursorKeys();
        this.characterType = characterType;
        this.player = scene.add.sprite(0, 0, TextureKeys.Boy)
            .setScale(0.4*charAdjustment)
            .setOrigin(0.5, 1)
            .play(this.characterType+'-run');

        scene.physics.add.existing(this);

        // add as child of Container
        this.add(this.player);

        const body = this.body as Phaser.Physics.Arcade.Body
        body.setSize(this.player.width*0.16*charAdjustmentBodyWidth, this.player.height*0.35*charAdjustmentBody);
        body.setOffset(this.player.displayWidth * -0.2, -this.player.displayHeight + 150);

        if (characterType == 'girl')
        {
            body.setOffset(this.player.displayWidth * -0.2, -this.player.displayHeight + 115);
        } else  {
            body.setOffset(this.player.displayWidth * -0.2, -this.player.displayHeight + 150);
        } 

        this.flapSound = this.scene.sound.add("flap", { loop: false });
    }

    preUpdate()
    {
        const body = this.body as Phaser.Physics.Arcade.Body;

        switch (this.mouseState)
        {
            case MouseState.Running:
            {             
                if (!this.scene.input.activePointer.isDown){
                    // to allow one jump during "fall"
                    if (body.blocked.down) {
                        this.playerJumps = 1;
                    }
                    this.canCheckClick = true;
                }

                if (this.scene.input.activePointer.isDown){
                    if (this.unlimitedJumps || (this.canCheckClick && (body.blocked.down || (this.playerJumps >= 0 && this.playerJumps < 2))))
                    {
                        this.canCheckClick = false;
                        if (body.blocked.down)
                        {
                            console.log("blocked down = ", this.playerJumps);
                            this.playerJumps = 0;
                        }

                        this.playerJumps++;
                        body.setVelocityY(jumpVelocity);
                        this.flapSound.play();
                        if (body.velocity.x == 0)
                        {
                            body.setVelocityX(minimalVelocityForMoveX);
                        }                            
                    }
                    else
                    {
                        if (body.velocity.x == 0)
                        {
                            body.setVelocityX(minimalVelocityForMoveX);
                        }           
                    }
                }

                // check if touching the ground
                if (body.blocked.down)
                {
                    if (body.blocked.right  && body.y > blockedBodyYValue)
                    {
                        this.player.play(this.characterType+'-idle', true);
                        body.setVelocityX(body.velocity.x+1);
                    }
                    else
                    {
                        // play run when touching the ground
                        this.player.play(this.characterType+'-run', true);
                        if (this.unlimitedJumps) {
                            body.setVelocityX(highVelocity);
                        } else {
                            body.setVelocityX(lowVelocity);
                        }
                        
                    }
                }
                else
                {
                    this.player.play(this.characterType+'-jump', true);
                }

                break;
            }

            case MouseState.Killed:
            {
                // reduce velocity to 99% of current value
                body.velocity.x *= 0.99;
            
                // once less than 5 we can say stop
                if (body.velocity.x <= velocityOfDeathX)
                {
                    this.mouseState = MouseState.Dead
                }
                
                break;
            }

            case MouseState.Dead:
            {
                this.scene.scene.run('GameOver');
                // make a complete stop
                body.setVelocity(0, 0);
                break;
            }
        }
    }

 
    kill(shouldPlayIdle: boolean)
    {
        if (this.mouseState !== MouseState.Running)
        {
            return;
        }

        this.mouseState = MouseState.Killed;

        if (shouldPlayIdle) {
            this.player.play(this.characterType+'-idle');
        } else {
            this.player.play(this.characterType+'-dizzy');
        }
        
   
        const body = this.body as Phaser.Physics.Arcade.Body;
        body.setAccelerationY(0);
        body.setVelocity(100, 0);
    }

    getMouseState(): MouseState
    {
        return this.mouseState;
    }

    startWings()
    {
        this.unlimitedJumps = true;
    }

    stopWings()
    {
        this.unlimitedJumps = false;
    }
}