import Phaser from 'phaser'
import { GameOptions, GameConsts } from './gameOptions';
import DisplayHelper from './displayHelper';

import { ResourcesService, Resources } from '../services/resources.service'
import TextureKeys from './textureKeys';


export default class GameOver extends Phaser.Scene
{
    private isFirstGame = false;
    private picDownloaded = false;
    private openGammataPortal = true;
    private width;
    private height;
    private screenshotX;
    private screenshotY;
    private homeX;
    private homeY;

    constructor(private resourcesService: ResourcesService)
    {
        super('GameOver')
    }

    create()
    {
        this.width = this.scale.width;
        this.height = this.scale.height;

        this.screenshotX = this.width - 300;
        this.screenshotY = this.height - 320;

        console.log('in gameover create');
        this.scene.pause('PlayGame');

        const getGameEndTextW = this.width * 0.5;
        const getGameEndTextH = this.height * 0.5 - 90;

        this.add.text(getGameEndTextW, getGameEndTextH, this.resourcesService.getGameEndText(), {
            fontFamily: 'Luckiest Guy',
            fontSize: '64px',
            color: GameConsts.yellowColor,
            fontStyle: 'normal',
            // stroke: '#FFFFFF',
            stroke: GameConsts.blackColor,
            strokeThickness: 4,
            shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
        })
        .setOrigin(0.5); 

        this.homeX = 50
        this.homeY = this.height - 100;

        this.add.image(
            this.width * 0.5,
            this.height * 0.5,
            TextureKeys.PlayButton
        ).setScale(0.15)
        .setOrigin(0.5, 0.5);

        this.add.image(
            this.homeX,
            this.homeY - 150,
            TextureKeys.HomeButton
        ).setScale(0.08)
        .setOrigin(0.5, 0.5);

        const homeButtonTextW = this.homeX + 30;
        const homeButtonTextH = this.homeY - 150;

        this.add.text(homeButtonTextW,homeButtonTextH, "Click home to create your own game", {
            fontFamily: 'Luckiest Guy',
			fontSize: '18px',
			color: GameConsts.blueColor,
			fontStyle: 'normal',
			stroke: '#FFFFFF',
			strokeThickness: 9,
        })
        .setOrigin(0, 0.5);
    
        this.add.image(
            this.homeX,
            this.homeY -50,
            TextureKeys.DownloadButton
        ).setScale(0.08)
        .setOrigin(0.5, 0.5);

        const loadButtonTextW = this.homeX + 30;
        const loadButtonTextH = this.homeY - 50;

        this.add.text(loadButtonTextW, loadButtonTextH, "Click download your game's snapshot", {
            fontFamily: 'Luckiest Guy',
			fontSize: '18px',
			color: GameConsts.blueColor,
			fontStyle: 'normal',
			stroke: '#FFFFFF',
			strokeThickness: 9,
        })
        .setOrigin(0, 0.5);

        const raceButtonText1W = this.width/2 + 150;
        const raceButtonText1H = this.height/2 - 15;

        var raceButtonText1 = this.add.text(raceButtonText1W, raceButtonText1H, 'Race', {
            fontSize: '40px',
            color: GameConsts.blackColor,
            fontStyle: 'normal',
            stroke: GameConsts.blackColor,
            strokeThickness: 1,
            shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
        })
        .setOrigin(0.5, 0.5)
        .setDepth(10);

        const raceButtonText2W = this.width/2 + 150;
        const raceButtonText2H = this.height/2 + 15;

        var raceButtonText2 = this.add.text(raceButtonText2W, raceButtonText2H, 'mode', {
            fontSize: '40px',
            color: GameConsts.blackColor,
            fontStyle: 'normal',
            stroke: GameConsts.blackColor,
            strokeThickness: 1,
            shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
        })
        .setOrigin(0.5, 0.5)
        .setDepth(10);

        const raceButtonW = this.width/2 + 150;
        const raceButtonH = this.height/2;

        const raceButton = this.add.sprite(raceButtonW, raceButtonH, TextureKeys.RaceModeButton)
        .setInteractive()
        .setOrigin(0.5, 0.5)
        .setScale(0.16)
        .on('pointerdown', () => {
            if (this.resourcesService.getGameMode() == 'RaceMode') { 
                DisplayHelper.toggleButtonColor(0xffff, 'black', 'black', raceButton, raceButtonText1, raceButtonText2);
                this.resourcesService.setGameMode('RegularMode');
            } else {
                DisplayHelper.toggleButtonColor(0xFF0000, 'white', 'white', raceButton, raceButtonText1, raceButtonText2);
                this.resourcesService.setGameMode('RaceMode');
            }
        });

        if (this.resourcesService.getGameMode() == 'RaceMode') { 
            DisplayHelper.toggleButtonColor(0xFF0000, 'white', 'white', raceButton, raceButtonText1, raceButtonText2);
        } else {
            DisplayHelper.toggleButtonColor(0xffff, 'black', 'black', raceButton, raceButtonText1, raceButtonText2);
        }
    }

    update () {

        if (this.input.activePointer.isDown)
        {
            if (Phaser.Math.Difference(this.input.activePointer.downX, this.homeX ) < 50 && Phaser.Math.Difference(this.input.activePointer.downY, this.homeY - 50) < 50 &&  !this.picDownloaded)
            {
                const link = document.createElement('a');
                link.download = 'download.png';
                link.href = this.resourcesService.getSnapshot();
                link.click();
                this.picDownloaded = true;
            }    
        }

        if (this.input.activePointer.isDown)
        {
            if ((Phaser.Math.Difference(this.input.activePointer.downX,  this.homeX) < 50 &&  Phaser.Math.Difference(this.input.activePointer.downY, this.homeY - 150) < 50) && this.openGammataPortal)
            {
                this.openGammataPortal = false;
                // window.open('https://gammata.azurewebsites.net');
                window.location.assign('https://setup.gammata.net')
            }
        }

        if (!this.input.activePointer.isDown)
        {
            this.openGammataPortal = true;
        }

        this.checkIfToStart(this.screenshotX, this.screenshotY);
    }
   
    sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }
    
    
    checkIfToStart(screenshotX: number, screenshotY: number)
    {      
        if (this.input.activePointer.isDown)
        {
            if ((Phaser.Math.Difference(this.input.activePointer.downX,  this.width/2) < 50 &&  Phaser.Math.Difference(this.input.activePointer.downY, this.height/2) < 50))
            {
                this.isFirstGame = false;
                this.scene.stop('GameOver');
                
                // stop and restart the Game scene
                this.scene.stop('PlayGame');
                this.scene.start('PlayGame');
            }    
        }
    }
}