enum TextureKeys
{
 Background = 'background',
 RocketMouse = 'rocket-mouse',
 Boy = 'Boy',
 Monster = 'monster',
 MouseHole = 'mouse-hole',
 Window1 = 'window1',
 Window2 = 'window2',
 Bookcase1 = 'bookcase-1',
 Bookcase2 = 'bookcase-2',
 LaserEnd = 'laser-end',
 LaserMiddle = 'lser-middle',
 Coin = 'coin',
 Lego1 = 'lego1',
 Lego2 = 'lego2',
 Lego3 = 'lego3',
 Lego4 = 'lego4',
 Lego5 = 'lego5',
 Car = 'car',
 Plane = 'plane',
 Cat = 'cat',
  Mouse = 'mouse',
 Worm = 'worm',
 MotorCycle = 'motorcycle',
 Table = 'table',
 Turtle = 'turtle',
 ParticleStar = 'particleStar',
 Wings = 'wings',
 number0 = 'number0',
 number1 =  'number1',
 number2 = 'number2',
 number3 = 'number3',
 number4 = 'number4',
 number5 = 'number5',
 number6 = 'number6',
 number7 = 'number7',
 number8 = 'number8',
 number9 = 'number9',
 PlayButton = 'playButton',
 HomeButton = 'homeButton',
 DownloadButton = 'downloadButton',
 RaceModeButton = 'raceModeButton',
 HelpButton = 'helpButton',
 Instructions = 'instructions',
 PhotoFrame = 'photoFrame',
 TV = 'tv',
 SHELVE = 'shelve',
 Cactus = 'cactus',
 Book = 'book',
 Monitor = 'monitor',
 MonitorDesk = 'monitorDesk',
 BED = 'bed',
 DRAWS = 'draws',
 SNOW = 'snow'
}

export default TextureKeys;