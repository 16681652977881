import Phaser from 'phaser'
import TextureKeys from './textureKeys';

export enum MouseState
{
    Running,
    Killed,
    Dead
}

export enum CharacterType
{
    Boy = 'boy',
    Girl = 'girl',
    Santa = 'santa'
}

export class ShadowPlayer extends Phaser.GameObjects.Container
{
    public shadow: Phaser.GameObjects.Sprite;
    private cursors: Phaser.Types.Input.Keyboard.CursorKeys;
    private mouseState = MouseState.Running;
    private characterType: string;

    constructor(scene: Phaser.Scene, x: number, y: number, characterType: string)
    {        
        super(scene, x, y);

        if (characterType != 'girl' && characterType != 'boy' && characterType != 'santa')
        {
            characterType = 'boy';
        }

        var charAdjustment = 1;
        var charAdjustmentBody = 1;
        if (characterType == 'girl')
        {
            console.log("adjusting for girl char");
            charAdjustment = 1.1;
            charAdjustmentBody = 1.15;
        }

        this.shadow =  scene.add.sprite(
            50, // x value
            570, // y value
            // 'boy_opening'
            TextureKeys.Boy
        ).play(characterType+'-run', true);
        this.shadow.setScrollFactor(0);
        this.shadow.setScale(0.4);
        this.shadow.setDepth(20);
        this.shadow.setOrigin(0.5, 1);
        this.shadow.setAlpha(0.4);
        this.shadow.setTint(0x0000);
    }

    preUpdate()
    {
        // this.shadow.play('boy-run', true);
    }

    public moveForward(delta: number)
    {
        this.shadow.setX(this.shadow.x + delta);
    }

    public getPosX()
    {
        return this.shadow.x;
    }


 
    kill()
    {
        if (this.mouseState !== MouseState.Running)
        {
            return;
        }

        this.mouseState = MouseState.Killed;
   
    }

    getMouseState(): MouseState
    {
        return this.mouseState;
    }

}