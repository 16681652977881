import { Component, Injectable } from '@angular/core';
import Phaser from 'phaser';
import { ScaleObject } from './scaleObject';
import { PreloadAssets } from './preloadAssets';
import { PlayGame} from './playGame';
import ParticleEffects from './ParticleEffects';

import { ResourcesService, Resources } from '../services/resources.service'
import GameOver from './gameOver';

declare function decodeURI(any: any):any; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'HomeRun';
  public phaserGame: Phaser.Game;
  
  constructor(private resourcesService: ResourcesService) {

    const configObject: Phaser.Types.Core.GameConfig = {
        type: Phaser.AUTO,
        scale: ScaleObject,
        dom: {
          createContainer: true
        },
        parent: 'homerun',
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 600 },
            debug: false
          }
        },
        scene: [new PreloadAssets(resourcesService), new PlayGame(this.resourcesService), new GameOver(this.resourcesService), new ParticleEffects()]
    }
    
    this.phaserGame = new Phaser.Game(configObject);
  }

  ngOnInit() {
    
  }
}


