import { GameOptions, GameConsts } from './gameOptions';
import TextureKeys from './textureKeys';
import { MouseState, Player } from './Player';
import { ShadowPlayer } from './shadowPlayer';
import { ResourcesService, Resources } from '../services/resources.service'
import TweenHelper from './tweenHelper' 


const mouseHoleYPos = 501;
const windowYPos = 200;
const wormXPos = 0;
const wormYPos = 640;
const mouseXPos = 0;
const mouseYPos = 660;
const monitorXPos = -1000;
const monitorYPos = 640;
const monitorDeskYPos = 750;
const mouseBodyHight = 30;
const mouseBodyWidth = 30;
const wormBodyHight = 30;
const wormBodyWidth = 30;
const newRecordTextX = 12;
const newRecordTextY = 150;
const currentRecordTextX = 12;
const currentRecordTextY = 105;
const scoreTextX = 12;
const scoreTextY = 57;
const levelLableTextX = 11;
const levelLableTextY = 10;
const tvX = -1000;
const tvY = 640;
const tvShelveX = -1000; 
const tvShelveY =  640;

export class PlayGame extends Phaser.Scene {

    private background: Phaser.GameObjects.TileSprite;
    private mouseHole: Phaser.GameObjects.Image[] = [];
    private mouse: Phaser.GameObjects.Sprite[] =[];
    private worm: Phaser.GameObjects.Sprite[] =[];
    private window1!: Phaser.GameObjects.Image;
    private window2!: Phaser.GameObjects.Image;
    private bookcases: Phaser.GameObjects.Image[] = [];
    private windows: Phaser.GameObjects.Image[] = [];
    private coins!: Phaser.Physics.Arcade.StaticGroup;
    private nextCoinsCreation = 0;
    private scorePersonalLabel!: Phaser.GameObjects.Text;
    private levelLabel!: Phaser.GameObjects.Text;
    private scoreText!: Phaser.GameObjects.Text;
    private monitorText!: Phaser.GameObjects.Text;
    private score = 0;
    private scoreboardGroup;
    private scoreTimer = 0;
    private difficultyLevel = 'Beginner';
    private currPic!: Phaser.GameObjects.Image;
    private nextPic!: Phaser.GameObjects.Image;
    private tvShelve!: Phaser.GameObjects.Image;
    private monitorDesk!: Phaser.GameObjects.Image;
    private monitor!: Phaser.GameObjects.Image;
    private customPics: Phaser.GameObjects.Image[] = [];
    private picsFrames: Phaser.GameObjects.Image[] = [];
    private currStartPos = 0;
    private currStartPosTurn = 0;
    private Player!: Player;
    private shadowPlayer!: ShadowPlayer;
    // private topPlayerPositionList: number[] = [1662,2344,3044,3745,4456,5162,5856,6550,7262,7962,8668,9374,10056,10774,11474,12191,12866,13363,14057,14763,15475,16180,16898,17610,18310,19004,19692,20392,21110,21821,22533,23221,23910,24009,24704,25404,26115,26798,27504,27957,28657,29357,30063,30751,31446,32175,32881,33569,34286,34992,35698,36392,37086,37494,37884,38602,39296,40002,40707,41205];
    private topPlayerPositionList: number[] = [1662,1800,1990,2344,3044,3745,3888,4456,5162,5500,5856,6550,7000,7262,7500,7962,8668,9374,10056,10774,11474,12191,12866,13363,14057,14057,14057,14057,14763,15475,16180,16898,17610,18310,19004,19692,20392,21110,21821,22533,23221,23910,24009,24704,25404,26115,26798,27504,27957,28657,29357,30063,30751,31446,32175,32881,33569,34286,34992,35698,36392,37086,37494,37884,37884,37884,38602,39296,40000,40000,40000];
    private currPlayerPositionList: number[] = [];
    private positionSampling: number;
    private positionComp: number;
    private shadowRunningTimer = 0;
    private shadowTargetPos = 50;
    private initialTime = 0;
    private timeUpdateTime = 0;
    private currTime = 0;
    private platformGroup:  Phaser.Physics.Arcade.StaticGroup;
    private platformPool:  Phaser.Physics.Arcade.StaticGroup;
    private monsterGroup:  Phaser.Physics.Arcade.Group;
    private monsterPool:  Phaser.Physics.Arcade.Group;
    private shouldRemoveScoreIfCollide = true;
    private hitSound: Phaser.Sound.BaseSound;
    private gameOver: Phaser.Sound.BaseSound;
    private showLastImage = false;
    private tv!: Phaser.GameObjects.Image;
    private recordHolderName = 'computer';

    private highScoreSound: Phaser.Sound.BaseSound;
    private scoreSound: Phaser.Sound.BaseSound;

    constructor(private resourcesService: ResourcesService) {
        super({
            key: 'PlayGame'
        });
    }

    init()
    {
        console.log('game init - start');
        this.score = 0;
        this.difficultyLevel = 'Beginner';
        this.nextCoinsCreation = 0;
        this.shouldRemoveScoreIfCollide = true;
        this.scene.run('ParticleEffects');
        this.positionSampling = GameOptions.speedSampling;
        this.positionComp = GameOptions.speedSampling;
        console.log('width - ', this.scale.width);
        this.initialTime = 0;
        this.timeUpdateTime = 0;
        this.currPlayerPositionList = [];
        this.shadowRunningTimer = 0;
        this.shadowTargetPos = 50;
    }
  
    create(): void {
        console.log('game create - start');
        // store the width and height of the game screen
        const width = this.scale.width;
        const height = this.scale.height;

        this.platformGroup = this.physics.add.staticGroup(); 
        this.platformPool = this.physics.add.staticGroup();

        this.monsterGroup = this.physics.add.group(); 
        this.monsterPool = this.physics.add.group();
      
        this.background! = this.add.tileSprite(0, 0, width, height, 'background')
        .setOrigin(0)
        .setScrollFactor(0, 0);

        const playerX =  width * 0.5;
        const playerY = height - 30;
        
        this.Player = new Player(this, playerX, playerY, this.resourcesService.getCharacterType());
        this.add.existing(this.Player);
        this.Player.setDepth(200);

        const shadowPlayerX = width * 0.5;
        const shadowPlayerY = height - 30;

        if (this.resourcesService.getGameMode() == "RaceMode") {
            this.shadowPlayer = new ShadowPlayer(this,shadowPlayerX, shadowPlayerY, this.resourcesService.getCharacterType());
            this.add.existing(this.shadowPlayer);
        }

        const body = this.Player.body as Phaser.Physics.Arcade.Body;
        body.setCollideWorldBounds(true);

        this.mouseHole[0] = this.add.image(
            Phaser.Math.Between(900, 1500), mouseHoleYPos, TextureKeys.MouseHole);

        this.mouseHole[1] = this.add.image(
            Phaser.Math.Between(900, 1500), mouseHoleYPos, TextureKeys.MouseHole);


        this.window1 = this.add.image(
            Phaser.Math.Between(900, 1300), windowYPos, TextureKeys.Window1);
            
        this.window2 = this.add.image(
            Phaser.Math.Between(1600, 2000), windowYPos, TextureKeys.Window2);

        this.windows = [this.window1, this.window2];
         
        this.worm[0] = this.add.sprite(wormXPos, wormYPos, TextureKeys.Worm);

        this.worm[1] = this.add.sprite(wormXPos, wormYPos, TextureKeys.Worm);

        this.worm[0].setOrigin(0.5, 1).setScale(0.5);
        this.worm[1].setOrigin(0.5, 1).setScale(0.5);

        this.physics.add.existing(this.worm[0]);
        this.physics.add.existing(this.worm[1]);
        (this.worm[0].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
        (this.worm[1].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
        this.worm[0].setActive(false);
        this.worm[0].setVisible(false);
        this.worm[0].setDepth(25);
        this.worm[1].setActive(false);
        this.worm[1].setVisible(false);
        this.worm[1].setDepth(25);

        this.mouse[0] = this.add.sprite(mouseXPos, mouseYPos, TextureKeys.Mouse).setFlipX(true);

        this.mouse[1] = this.add.sprite(mouseXPos, mouseYPos, TextureKeys.Mouse).setFlipX(true);

        this.mouse[0].setOrigin(0.5, 1).setScale(0.3);
        this.mouse[1].setOrigin(0.5, 1).setScale(0.3);

        this.physics.add.existing(this.mouse[0]);
        this.physics.add.existing(this.mouse[1]);
        (this.mouse[0].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
        (this.mouse[1].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
        this.mouse[0].setActive(false);
        this.mouse[0].setVisible(false);
        this.mouse[0].setDepth(25);
        this.mouse[1].setActive(false);
        this.mouse[1].setVisible(false);
        this.mouse[1].setDepth(25);

        // personalization
        const currPicH = height* 0.6 + 30;
        this.customPics = [];
        this.picsFrames = [];
        this.scoreTimer = 0;
        for (var j=0; j< 2; j++) {
            for (var i=0; i< this.resourcesService.getCustomImagesList().length; i++) {
                var currPic = this.add.image(
                    Phaser.Math.Between(2500, 2700),
                    currPicH, //GameOptions.picHeight,
                    this.resourcesService.getCustomImagesList()[i]
                ).setOrigin(0.5, 1);

                var scale =  height* 0.6/currPic.height;
                currPic.setScale(scale);
                currPic.setVisible(false);
                this.customPics.push(currPic);

                // pic for monitor
                if (i == 0)
                {
                    const currTime = new Date();                 

                    currPic.setY(currPic.y -60);
                    this.monitorText = this.add.text(12, 57, currTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}), {
                        fontFamily: 'Trispace',
                        fontSize: '35px',
                        color: GameConsts.whiteColor,
                        fontStyle: 'normal',
                    })
                    .setDepth(16)
                    .setOrigin(0, 1);
                }

                const frameXPos = currPic.x;
                const frameYPos = currPic.y + 13;

                var frame = this.add.sprite(frameXPos, frameYPos, TextureKeys.PhotoFrame);

                currPic.setDepth(3);
    
                frame.displayWidth = currPic.displayWidth+30;
                frame.displayHeight = currPic.displayHeight+26;

                frame.setOrigin(0.5, 1);
                frame.setDepth(2);
                frame.setVisible(false);
                this.picsFrames.push(frame);
            }
        }

        this.monitor = this.add.sprite(monitorXPos, monitorYPos, TextureKeys.Monitor);

        this.monitor.setScale(0.9);
        this.monitor.setDepth(12);
        this.monitor.setOrigin(0.5, 1);

        this.monitorDesk = this.add.sprite(monitorXPos, monitorDeskYPos, TextureKeys.MonitorDesk);

        this.monitorDesk.setScale(0.3);
        this.monitorDesk.setDepth(12);
        this.monitorDesk.setOrigin(0.5, 1);

        this.coins = this.physics.add.staticGroup();
            
        this.physics.world.setBounds(
            0, 0, // x, y
            Number.MAX_SAFE_INTEGER, height - 90 // width, height
        );
        
        this.cameras.main.startFollow(this.Player);
        this.cameras.main.setBounds(0, 0, Number.MAX_SAFE_INTEGER, height);
        
        // create overlap detection
        this.physics.add.overlap(
            this.coins,
            this.Player,
            this.handleCollectCoin,
            undefined,
            this
        ); 

        // Sounds
        this.hitSound = this.sound.add("hit", { loop: false });
        this.gameOver = this.sound.add("gamerover", { loop: false });
        this.scoreSound = this.sound.add("score", { loop: false });

        this.scoreboardGroup = this.physics.add.staticGroup()
        const score0 = this.scoreboardGroup.create(30, 30, TextureKeys.number0)
        score0.setDepth(20);

        this.preloadSecondLevel();

        var gameRecord = this.resourcesService.getGameRecord();
        if (gameRecord && gameRecord.includes(":")) {
            console.log('got record log from server');
            var gameRecordElements = gameRecord.split(':');
            this.recordHolderName = (gameRecordElements[0].split(','))[0];
            this.topPlayerPositionList = gameRecordElements[1].split(',').map(element => {
                return Number(element);
              });
        }

        if (this.resourcesService.getCharacterType() == 'santa'){
            this.emitSnow();
        }
        
        console.log('game create - end');
    }

    update(time: number): void {

        this.currTime = time;
        if (this.resourcesService.getGameMode() == "RaceMode") {
            if (this.initialTime == 0)
            {
                this.initialTime = time;
            }

            if (this.timeUpdateTime < time) {
                this.timeUpdateTime = time + 500;
                this.updateScoreboard(time);
            }
    
            this.samplePosition(time - this.initialTime);
            this.calcShadowPosition(time - this.initialTime);    
        }

        
        this.spawnCoinsAndWings();

        const scrollX = this.cameras.main.scrollX
        const rightEdge = scrollX + this.scale.width

        this.platformGroup.getChildren().forEach(function(platform){
            const scrollX = this.cameras.main.scrollX;
            let plat = platform as Phaser.GameObjects.Sprite;
            if(plat.x + GameOptions.bookcase1Width/2 < scrollX){
                this.platformGroup.killAndHide(platform);
                this.platformGroup.remove(platform);
                this.platformPool.add(platform);
                // console.log("remove books ");
            }
        }, this);

        this.monsterGroup.getChildren().forEach(function(monster){
            const scrollX = this.cameras.main.scrollX;
            let monst = monster as Phaser.GameObjects.Sprite;
            if(monst.x < scrollX){
                this.monsterGroup.killAndHide(monster);
                this.monsterGroup.remove(monster);
                this.monsterPool.add(monster);
                // console.log("remove monster ");
            }
        }, this);

        let minDistance = rightEdge - scrollX;
        this.platformGroup.getChildren().forEach(function(platform){
            let plat = platform as Phaser.GameObjects.Sprite;
            let platformDistance = rightEdge - plat.x - plat.displayWidth / 2;
            if(platformDistance < minDistance){

                minDistance = platformDistance;
            }
        });

        if (minDistance > 750)
        {
            if (this.currStartPos < scrollX)
            {
                this.currStartPosTurn++;
                this.currStartPos = scrollX + this.scale.width;
                if (this.currStartPosTurn % 2 == 0)
                {
                    // console.log('going to present windows etc. scroll is '+scrollX.toString());
                    this.wrapWindows();
                }           
            }

            let width = GameOptions.bookcase1Width * 2;
            var posX = Phaser.Math.Between(rightEdge + width, rightEdge + width + 200);
            
            var minX = 150;
            var maxX = 850;
            var monsterRelPosX = Phaser.Math.Between(minX, maxX);
            var picRelPosX = Phaser.Math.Between(350, 650);
            var mouseHoleRelPosX = monsterRelPosX > 500 ? monsterRelPosX - 250: monsterRelPosX + 350;
            var monsterPosX = posX + GameOptions.bookcase1Width + monsterRelPosX;
            var mouseHolePosX = posX + GameOptions.bookcase1Width + mouseHoleRelPosX;
            var picPosX = posX + GameOptions.bookcase1Width + picRelPosX;
            
            this.addPlatform(Phaser.Math.Between(0.5, 0.7), posX, 500, monsterPosX, picPosX);

            if (this.difficultyLevel == 'Pro' || (this.resourcesService.getGameMode() == 'RaceMode' && scrollX > GameOptions.scrolForNextLevel)) {
                this.wrapMouseHole(mouseHolePosX);
                this.showMouse();
            }
        }

        this.background.setTilePosition(this.cameras.main.scrollX);
        this.showScoreMeaasge(time);
    }

    
    private showMouse()
    {
        const scrollX = this.cameras.main.scrollX;
        const rightEdge = scrollX + this.scale.width;

        for (var i = 0; i< 2; i++)
        {
           const mouseBody = this.moveMonster(this.mouse[i], mouseBodyWidth, mouseBodyHight, scrollX, rightEdge, 'mouse-walk');
        }

        for (var i = 0; i< 2; i++)
        {
            const wormBody = this.moveMonster(this.worm[i], wormBodyWidth, wormBodyHight, scrollX, rightEdge, 'worm-walk');
        }
    }

    private moveMonster(monster, monsterWidth, monsterHight, scrollX, rightEdge, animationName)
    {
        const monsterBody = monster.body as Phaser.Physics.Arcade.Body; 
        monsterBody.setSize(monsterWidth, monsterHight);
        monsterBody.setVelocityX(0);
        if (monster.x > scrollX && monster.x < rightEdge) {
            var dist =  monster.x - this.Player.x;                
            if (dist > 0 && dist < 1100)
            {
                monster.play(animationName, true);
                const monsterBody = monster.body as Phaser.Physics.Arcade.Body;
                monsterBody.setVelocityX(-70);
            }
            else
            {
                monster.play(animationName, true);
            }

            if (dist < 0)
            {
                monsterBody.setVelocityX(-70);
            }

        }
    }

    private wrapMouseHole(posX: number)
    {
        const scrollX = this.cameras.main.scrollX;
        const rightEdge = scrollX + this.scale.width;

        var indexH = this.mouseHole[0].x > this.mouseHole[1].x ? 1:0;
        if (Math.random() > 0.5)
        {
            this.setupMonsters(this.mouseHole[indexH], posX, this.mouse[0], this.mouse[1], this.mouse, 5, 6, mouseBodyWidth, mouseBodyHight);
         } 
         else {
            this.setupMonsters(this.mouseHole[indexH], posX, this.worm[0], this.worm[1], this.worm, 8, 9, wormBodyWidth, wormBodyHight);
        }
    }

    private setupMonsters (monsterHoleIndex, posX, monsterIndex0, monsterIndex1, monster, between1, between2, monsterBodyWidth, monsterBodyHight)
    {
        var indexM = monsterIndex0.x > monsterIndex1.x ? 1:0;
        monster[indexM].setScale(Phaser.Math.Between(between1, between2)/20);
        monsterHoleIndex.setX(posX);
        monster[indexM].setX(monsterHoleIndex.x);
        monster[indexM].setVisible(true);
        monster[indexM].setActive(true);
        const monsterBody = this.worm[indexM].body as Phaser.Physics.Arcade.Body;
        monsterBody.setSize(monsterBodyWidth, monsterBodyHight);

        this.physics.add.collider(monster[indexM],  this.Player, () => {
        if (this.resourcesService.getGameMode() == "RaceMode") {    
            const playerBody = this.Player.body as Phaser.Physics.Arcade.Body;   
            playerBody.setVelocityX(100);
        }
        else {
            this.stopGame();
            const monsterBody = monster[indexM].body as Phaser.Physics.Arcade.Body;         
            monsterBody.setVelocityX(0);
        }
            }, undefined, this);
    }

    private stopGame()
    {
        if (this.Player.getMouseState() == MouseState.Killed)
        {
            return;
        }

        var showAminationOfIdle = false;
        if (this.resourcesService.getGameMode() == 'RaceMode') {
            showAminationOfIdle = true;
            this.add.text(currentRecordTextX, currentRecordTextY, "Current record by " + this.recordHolderName + ' - ' + (this.topPlayerPositionList.length*2 + 2), {
                fontFamily: 'Luckiest Guy',
                fontSize: '46px',
                color: GameConsts.lightBlueColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 6
            })
            .setScrollFactor(0)
            .setDepth(60);

            var endTime = Math.round((this.currTime - this.initialTime)/1000)
            if (this.topPlayerPositionList.length*2 + 2 > endTime)
            {
                // new record
                this.add.text(newRecordTextX, newRecordTextY, "You've set a new record - " + endTime + ", well done", {
                    fontFamily: 'Luckiest Guy',
                    fontSize: '46px',
                    color: GameConsts.lightBlueColor,
                    fontStyle: 'normal',
                    stroke: GameConsts.blackColor,
                    strokeThickness: 6
                })
                .setScrollFactor(0)
                .setDepth(60);
    
                var recordLog = this.resourcesService.getPlayerName() + ","+ endTime +":"+  this.currPlayerPositionList.toString();
                console.log(recordLog);
                this.resourcesService.updateRecord(this.resourcesService.getQueryString('gameid'), recordLog);
            }

            this.sound.play("cheer", { loop: false });
        } else {
            this.gameOver.play();
        }

        
        this.Player.kill(showAminationOfIdle);
        if (this.scorePersonalLabel)
        {
            this.scorePersonalLabel.setVisible(false);
        }
    }

    private wrapWindows()
    {
        console.log('in warp windows');
        const scrollX = this.cameras.main.scrollX
        const rightEdge = scrollX + this.scale.width

        // multiply by 2 to add some more padding
        let width = this.window1.width * 2
        if (this.window1.x + width < scrollX)
        {
            this.window1.x = Phaser.Math.Between(
            rightEdge + width,
            // rightEdge + width + 800
            rightEdge + width + 200
            );

            const overlap = this.bookcases.find(bc => {
                    return Math.abs(this.window1.x - bc.x) <= this.window1.width });
            
            this.window1.visible = !overlap;
        }

        width = this.window2.width;
        if (this.window2.x + width < scrollX)
        {
            this.window2.x = Phaser.Math.Between(
            this.window1.x + width,
            this.window1.x + width + 100
            );

            const overlap = this.bookcases.find(bc => {
                return Math.abs(this.window2.x - bc.x) <= this.window2.width });
        
            this.window2.visible = !overlap;
        }
    }

    private checkOverlap(imageA, imageB) {

        var boundsA = imageA.getBounds();
        var boundsB = imageB.getBounds();
    
        return Phaser.Geom.Rectangle.Overlaps(boundsA, boundsB);
    
    }

    private wrapPics(picPosX)
    {
        const scrollX = this.cameras.main.scrollX
        const rightEdge = scrollX + this.scale.width

        var randPicId = Phaser.Math.Between(
            0,
            this.customPics.length - 1
        );

        if (this.showLastImage)
        {
            randPicId = this.customPics.length - 1;
            this.showLastImage = false;
        }
        
        this.nextPic = this.customPics[randPicId];

        if (this.currPic == this.nextPic)
        {
            randPicId = (randPicId+1)%this.customPics.length;
            this.nextPic = this.customPics[randPicId];
        }

        console.log('in warp pics, pic x- ' + this.nextPic.x + 'scroll x-' + scrollX);

        this.nextPic.setX(picPosX);
        this.windows.forEach(win => {
            console.log('window x and y ', win.x, win.x + win.displayWidth)
        })

        console.log('next pic x and y ', this.nextPic.x, this.nextPic.x + this.nextPic.displayWidth)
        

        const overlap = this.windows.find(bc => {
            return (Math.abs(this.nextPic.x - bc.x) <= bc.displayWidth + 10) || (Math.abs(bc.x - this.nextPic.x) <= this.nextPic.displayWidth + 10) });
    
        console.log('over lap ', overlap);
        this.nextPic.setVisible(!overlap);

        if (this.nextPic.visible)
        {
            console.log('showing pic, pic num', randPicId);          

            if ((randPicId == this.customPics.length - 1) && 
                (this.difficultyLevel == 'Pro' || (this.resourcesService.getGameMode() == 'RaceMode' && scrollX > GameOptions.scrolForNextLevel))) {
                console.log("rnad pic", randPicId);
                console.log("pics length", this.customPics.length);
                this.tv.x = this.nextPic.x + 25;
                this.tv.y = this.nextPic.y + 25;
                this.tvShelve.x = this.nextPic.x + 25;
                this.tvShelve.y = this.nextPic.y + 40;
    
                this.nextPic.displayHeight=  this.tv.displayHeight*0.5;
                this.nextPic.displayWidth=  this.tv.displayWidth*0.7;
                this.nextPic.setDepth(14);
                TweenHelper.flashElement(this, this.nextPic, true, 'Elastic', 1500, 2);
            } else if (randPicId == 0) {
                this.monitor.x = this.nextPic.x + 3;
                this.monitor.y = this.nextPic.y + 60;
                this.monitorDesk.x = this.nextPic.x + 45;
                this.monitorDesk.y = this.nextPic.y + 210;
    
                this.nextPic.displayHeight=  this.monitor.displayHeight*0.7;
                this.nextPic.displayWidth=  this.monitor.displayWidth*0.9;
                this.nextPic.setDepth(14);

                this.monitorText.x = this.nextPic.x - this.nextPic.displayWidth/2;
                this.monitorText.y = this.nextPic.y;
            }
            else {
                    this.picsFrames[randPicId].setX(this.nextPic.x);
                    this.picsFrames[randPicId].setY(this.nextPic.y+13);
                    this.picsFrames[randPicId].setVisible(true);    
            }  

            this.currPic = this.nextPic;
        }
    }

    private spawnCoinsAndWings()
    {
        const scrollX = this.cameras.main.scrollX;
        const rightEdge = scrollX + this.scale.width;

        if (this.nextCoinsCreation > rightEdge)
        {
            return;
        }
        
        this.nextCoinsCreation = rightEdge + this.scale.width + 500;

        this.coins.children.each(child => {
            const coin = child as Phaser.Physics.Arcade.Sprite
            this.coins.killAndHide(coin)
            coin.body.enable = false
            });
        
        // start at 100 pixels past the right side of the screen
        let x = rightEdge + 100;
        
        // random number from 1 - 20
        var numCoins = Phaser.Math.Between(1, 7);

        if (this.resourcesService.getGameMode() == "RaceMode") {
            numCoins = 0;
        }
        

        var randBigCoin: number = Math.floor(Math.random() * numCoins*3);
        
        // the coins based on random number
        for (let i = 0; i < numCoins; ++i)
        {
            const coin = this.coins.get(
            x,
            Phaser.Math.Between(250, this.scale.height - 250),
            TextureKeys.Coin
            ) as Phaser.Physics.Arcade.Sprite;
            
            // make sure coin is active and visible
            coin.setVisible(true);
            coin.setActive(true);
            coin.setDepth(20);
            coin.play('coins');           
            if (false)
            {
                coin.setScale(0.7);
                coin.setName('big');
            }
            else
            {
                coin.setName('regular');
                coin.setScale(0.4);
            }
            
            // enable and adjust physics body to be a circle
            const body = coin.body as Phaser.Physics.Arcade.StaticBody;
            body.setCircle(body.width * 0.5);
            body.enable = true;
            body.updateFromGameObject();
            
            // move x a random amount
            x += coin.width * 1.5;
        }

        if ((this.difficultyLevel == 'Pro' || (this.resourcesService.getGameMode() == 'RaceMode' && scrollX > GameOptions.scrolForNextLevel)) && Math.random() < 0.2) {
            var wings = this.add.sprite(x + 150, Phaser.Math.Between(250, this.scale.height - 250), TextureKeys.Wings);
            wings.setDepth(20);
            wings.setScale(0.15);

            this.tweens.add({
                targets: wings,
                scale: 0.3,
                duration:1000,
                repeat: 10
            });

            // enable and adjust physics body to be a circle
            this.physics.add.existing(wings, true);
            const body = wings.body as Phaser.Physics.Arcade.StaticBody;
            body.setCircle(body.width * 0.5);
            body.enable = true;
            body.updateFromGameObject();

            // create overlap detection
            this.physics.add.overlap(
               wings,
               this.Player,
               this.handleCollectWings,
               undefined,
               this
           );    
        }
    }

    private handleCollectCoin(
        obj1: Phaser.GameObjects.GameObject,
        obj2: Phaser.GameObjects.GameObject
        )
    {
        const coin = obj2 as Phaser.Physics.Arcade.Sprite

        if (coin.name == 'big')
        {
            const particleEffects = this.scene.get('ParticleEffects')
            particleEffects.events.emit('trail-to', {
                fromX: coin.x - this.cameras.main.scrollX,
                fromY: coin.y,
                toX: 50,
                toY: 50
            })    

            this.score += 2;
        }
        
        // use the group to hide it
        this.coins.killAndHide(coin);
        
        // and turn off the physics body
        coin.body.enable = false;

        this.score += 1;
        this.scoreSound.play();

        this.updateScoreboard(this.currTime);

    }

    private handleCollectWings(
        obj1: Phaser.GameObjects.GameObject,
        obj2: Phaser.GameObjects.GameObject
        )
    {
        const wings = obj1 as Phaser.Physics.Arcade.Sprite
        const player = obj2 as Phaser.Physics.Arcade.Sprite

        const particleEffects = this.scene.get('ParticleEffects')
        particleEffects.events.emit('trail-to', {
            fromX: player.x - this.cameras.main.scrollX,
            fromY: player.y,
            toX: 0,
            toY: this.scale.height
        }) 

         // waiting 2 seconds
         this.time.addEvent({
            // delay, in milliseconds
            delay: 6000,
            // callback function, to restart the scene
            callback: function(){
                this.Player.stopWings();
            },
            // callback scope
            callbackScope: this
        });

        wings.setVisible(false);
        wings.setActive(false);
        this.Player.startWings();

        this.scoreSound.play();
    }

    private updateScoreboard(time: number) {

        if (this.scoreText) {
            this.scoreText.destroy();
        }

        if (this.resourcesService.getGameMode() == "RaceMode") {
            this.scoreText = this.add.text(scoreTextX, scoreTextY, "Time - " + Math.round((time - this.initialTime)/1000), {
                fontFamily: 'Luckiest Guy',
                fontSize: '46px',
                color: GameConsts.lightBlueColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 6
            })
            .setScrollFactor(0)
            .setDepth(60);
            
            if (this.levelLabel) {
                this.levelLabel.destroy();
            }
    
            const scrollX = this.cameras.main.scrollX;

            this.levelLabel = this.add.text(levelLableTextX, levelLableTextY, "Distance - " + Math.round(scrollX/100), {
                fontFamily: 'Luckiest Guy',
                fontSize: '46px',
                color: GameConsts.lightBlueColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 6
            })
            .setScrollFactor(0)
            .setDepth(60);
    
            this.levelLabel.setVisible(true);

            if (scrollX/100 > GameOptions.RaceDistance)
            {
                this.stopGame();
            }
        }  else {
            this.scoreText = this.add.text(scoreTextX, scoreTextY, "SCORE  " + this.score, {
                fontFamily: 'Luckiest Guy',
                fontSize: '46px',
                color: GameConsts.lightBlueColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 6
            })
            .setScrollFactor(0)
            .setDepth(5);
        }
    }

    private showScoreMeaasge(time: number)
    {
        if (this.resourcesService.getGameMode() == "RaceMode") {
            return;
        }

        if (this.scoreTimer != 0 && this.scoreTimer < time)
        {
            this.scorePersonalLabel.setVisible(false);
        }
        else if (this.score > GameOptions.proLevelScore && this.score < GameOptions.masterLevelScore && this.scoreTimer == 0)
        {
            this.difficultyLevel = 'Pro';
            this.scoreTimer = time + 20000;

            if (!this.highScoreSound){
                this.highScoreSound = this.sound.add("highScore", { loop: false });
            }

            const scorePersonalLabelTextX = this.scale.width/2;
            const scorePersonalLabelTextY = 80;

            this.highScoreSound.play();
            this.scorePersonalLabel = this.add.text(scorePersonalLabelTextX, scorePersonalLabelTextY, this.resourcesService.getScoreMessageText(), {
                fontFamily: 'Luckiest Guy',
                fontSize: '48px',
                color: GameConsts.yellowColor,
                fontStyle: 'normal',
                stroke: GameConsts.blackColor,
                strokeThickness: 9,
                shadow: { offsetY: 1, offsetX: 1, blur: 2, fill: true, color: GameConsts.blackColor, stroke: true }
            })
            .setOrigin(0.5)
            .setScrollFactor(0)
            .setDepth(15);
            
            TweenHelper.flashElement(this, this.scorePersonalLabel, true, 'Linear', 1500, 100);

            // Add high score image
            var currPic = this.add.image(
                0,
                this.scale.height* 0.6 + 5, //GameOptions.picHeight,
                'endimage'
            ).setOrigin(0.5, 1);

            var scale =  this.scale.height* 0.6/currPic.height;
            currPic.setScale(scale);
            currPic.setVisible(false);
            this.customPics.push(currPic);
            this.showLastImage = true;

            /*
            this.tvPic = this.add.image(
                0,
                this.scale.height* 0.6 + 30, //GameOptions.picHeight,
                'endimage'
            ).setOrigin(0.5, 1);

            this.tvPic.setVisible(false);
            */

            const frameXPos = currPic.x;
            const frameYPos = currPic.y + 13;

            var frame = this.add.sprite(
                frameXPos, // x value
                frameYPos, // y value
                TextureKeys.PhotoFrame
            );

            currPic.setDepth(3);

            frame.displayWidth = currPic.displayWidth+30;
            frame.displayHeight = currPic.displayHeight+26;
            frame.setOrigin(0.5, 1);
            frame.setDepth(2);
            frame.setVisible(false);
            this.picsFrames.push(frame);
            
        }
        else if (this.score > GameOptions.masterLevelScore)
        {
            this.difficultyLevel = 'Master';
        }

        if (this.levelLabel && this.levelLabel.text == "LEVEL - " + this.difficultyLevel && this.levelLabel.visible) {
            return;
        }

        if (this.levelLabel) {
            this.levelLabel.destroy();
        }

        this.levelLabel = this.add.text(levelLableTextX, levelLableTextY, "LEVEL - " + this.difficultyLevel, {
            fontFamily: 'Luckiest Guy',
            fontSize: '46px',
			color: GameConsts.lightBlueColor,
			fontStyle: 'normal',
			stroke: GameConsts.blackColor,
			strokeThickness: 6
        })
        .setScrollFactor(0)
        .setDepth(5);

        this.levelLabel.setVisible(true);
    }

    private addPlatform(platformWidth, posX, posY, monsterPosX, picPosX) {
        let platform: Phaser.GameObjects.Sprite;
        if (this.platformPool.getLength() >= 1 && 
            ((this.platformPool.getLength() + this.platformGroup.getLength() -1 > this.score/10 
            && this.platformPool.getLength() + this.platformGroup.getLength() -1 > (this.currTime - this.initialTime)/25000) 
            || this.platformPool.getLength() + this.platformGroup.getLength() == 5)) {
        
          console.log("using plaform score is", this.score);
          platform = this.platformPool.getFirst();
          platform.x = posX;
          platform.y = posY;
          platform.active = true;
          platform.visible = true;
          const body = platform.body as Phaser.Physics.Arcade.Body;
          platform.body.position.x = posX - body.width/2;
          platform.body.position.y = posY - body.height/2;
          this.platformPool.remove(platform);
          this.platformGroup.add(platform);
        } else {
            var isBK1 = false;
            var isBK2 = false;
            var isTable = false;
            var isBed = false;
            var isDraws = false;
            this.platformPool.getChildren().forEach(function(platform){
                let plat = platform as Phaser.GameObjects.Sprite;
                if(plat.name == 'bk1'){
                    isBK1 = true;
                }

                if(plat.name == 'bk2'){
                    isBK2 = true;
                }

                if(plat.name == 'table'){
                    isTable = true;
                }

                if(plat.name == 'bed'){
                    isBed = true;
                }

                if(plat.name == 'draws'){
                    isDraws = true;
                }
            });

            this.platformGroup.getChildren().forEach(function(platform){
                let plat = platform as Phaser.GameObjects.Sprite;
                if(plat.name == 'bk1'){
                    isBK1 = true;
                }

                if(plat.name == 'bk2'){
                    isBK2 = true;
                }

                if(plat.name == 'table'){
                    isTable = true;
                }

                if(plat.name == 'bed'){
                    isBed = true;
                }

                if(plat.name == 'draws'){
                    isDraws = true;
                }
            });

            if (!isBK1)
            {
                platform = this.addOneObstacle(platform, posX, posY-10, TextureKeys.Bookcase1,  0.65, 0.55, 0.5, 25, 0.85, 0.9, "bk1");
            }
            else if (!isBK2) {
                platform = this.addOneObstacle(platform, posX, posY-20, TextureKeys.Bookcase2,  0.85, 0.35, 0.4, 25, 1, 0.9, "bk2");

            } else if(!isTable) {
                platform = this.addOneObstacle(platform, posX, posY+40, TextureKeys.Table,  1, 1, 0.4, 25, 0.85, 0.8, "table");
            } else if (!isBed) {
                platform = this.addOneObstacle(platform, posX, posY+30, TextureKeys.BED,  1, 1, 0.35, 25, 0.85, 0.58, "bed");
            } else if (!isDraws) {
                platform = this.addOneObstacle(platform, posX, posY+10, TextureKeys.DRAWS,  1, 1, 0.4, 25, 0.85, 0.85, "draws");
            }


          this.platformGroup.add(platform);
          this.physics.add.collider(platform,  this.Player, (obj1, obj2) => {
            if (obj2.body.blocked.right)
            {
                if (this.shouldRemoveScoreIfCollide && !(obj2.body.blocked.down && obj2.body.y < 300))
                {
                    if (this.score > 0)
                    {
                        this.hitSound.play();
                        this.score-= Math.min(3, this.score);
                        this.updateScoreboard(this.currTime);
                        
                        for (var i= 0; i< 3;i++)
                        {
                            var coin = this.add.sprite(this.Player.x, this.Player.y-20, TextureKeys.Coin);
                            coin.setScale(0.5);
                            coin.setDepth(200);
                            this.physics.add.existing(coin);
                            const body = coin.body as Phaser.Physics.Arcade.Body
                            body.setVelocityX(-(i+1)*70);
                            body.setVelocityY(-60);
                            body.setCollideWorldBounds(true);
                            this.physics.add.existing(coin);

                            this.tweens.add({
                                targets: coin,
                                alpha: 0,
                                duration: 6000,
                                repeat: 0
                            });
                        }
                    }
                    this.shouldRemoveScoreIfCollide = false;    
                    this.cameras.main.shake(200, 0.003);
                }
            }
            else
            {
                this.shouldRemoveScoreIfCollide = true;
            }
            }, undefined, this);
        }

        this.wrapPics(picPosX);

        for (var i = 0; i< 1;i++) {
            let monster;
            if (this.monsterPool.getLength() > 5) {
                monster = this.monsterPool.getFirst();
                monster.setActive(true);
                monster.setVisible(true);
                monster.setX(monsterPosX);
                monster.setY(posY+20);
                monster.setDepth(20);
                this.physics.add.existing(monster);
                this.monsterPool.remove(monster);
                this.monsterGroup.add(monster);
                monster.body.setCollideWorldBounds(true);
            } else {     
                switch ((posX+i)%3)
                {
                case 0:
                        // monster = this.add.sprite(posX + GameOptions.bookcase1Width + Phaser.Math.Between(150, 900), posY + 20, TextureKeys.Lego2);
                        monster = this.add.sprite(monsterPosX, posY + 20, TextureKeys.Lego2);
                        monster.setScale(0.2);
                        break;
                case 1:
                        // monster = this.add.sprite(posX + GameOptions.bookcase1Width + Phaser.Math.Between(150, 900), posY + 20, TextureKeys.Turtle);   
                        // monster = this.add.sprite(monsterPosX, posY + 20, TextureKeys.Turtle);
                        monster = this.add.sprite(monsterPosX, posY + 20, TextureKeys.Cactus);
                        monster.setScale(0.3);
                        break;  
                case 2:
                        // monster = this.add.sprite(posX + GameOptions.bookcase1Width + Phaser.Math.Between(150, 900), posY + 20, TextureKeys.Cat);
                        monster = this.add.sprite(monsterPosX, posY + 20, TextureKeys.Book);
                        // monster.setScale(0.15);
                        monster.setScale(0.35);
                        break;

                }         
                
                monster.setActive(true);
                monster.setVisible(true);
                monster.setDepth(20);
                
                this.physics.add.existing(monster);
                const body = monster.body as Phaser.Physics.Arcade.Body
                body.setSize(body.width*0.8, body.height*0.8);
                body.setAllowDrag(false);
                this.monsterGroup.add(monster);
                this.physics.add.collider(monster,  this.Player, () => {
                    const monsterBody =monster.body as Phaser.Physics.Arcade.Body;         
                    if (this.resourcesService.getGameMode() == "RaceMode") {    
                        const playerBody = this.Player.body as Phaser.Physics.Arcade.Body;   
                        playerBody.setVelocityX(0);        
                        monsterBody.setVelocityX(0);
                    } else {
                        monsterBody.setVelocityX(0);
                        this.stopGame();
                    }
                    }, undefined, this);
                monster.body.setCollideWorldBounds(true);
            }
        }       
      }

      private addOneObstacle(platform, posX, posY, textureKey, widthScale, heightScale, scale, depth, bodyWidthScale, bodyHeightScale, platformName) {
        platform = this.add.sprite(posX, posY, textureKey);
        platform.setDisplaySize(platform.width * widthScale, platform.height *heightScale);
        platform.setScale(scale);
        platform.setDepth(depth);
        this.physics.add.existing(platform, true);
        const platBody = platform.body as Phaser.Physics.Arcade.Body;
        platBody.setSize(platBody.width*bodyWidthScale, platBody.height*bodyHeightScale);
        platform.name = platformName;
        return platform;
      }

      private async preloadSecondLevel()
      {
        console.log("preload 2");
        this.load.image(TextureKeys.Mouse, 'assets/__grey_mouse_run_000.png');
        this.load.image(TextureKeys.Worm, 'assets/worm.png');
        this.load.image(TextureKeys.Wings, 'assets/red.png');
        this.load.image(TextureKeys.TV, 'assets/old_tele.png');
        this.load.image(TextureKeys.SHELVE, 'assets/light_wall_shelf.png');
        this.load.image(TextureKeys.BED, 'assets/bed.png');
        this.load.image(TextureKeys.DRAWS, 'assets/white_five_draw.png');
        this.load.image(TextureKeys.DownloadButton, 'assets/download.png');

        this.load.audio("highScore", ["assets/mixkit-game-bonus-reached-2065.wav"]);
        this.load.audio("cheer", ["assets/cheer.mp3"]);

        this.load.atlas(
            'mouse-anim',
            'assets/mouse-spritesheet.png',
            'assets/mouse-spritesheet.json');

        this.load.atlas(
                    'worm-anim',
                    'assets/worm-spritesheet.png',
                    'assets/worm-spritesheet.json');

        this.load.once(Phaser.Loader.Events.COMPLETE, () => {
            this.anims.create({
                key: 'mouse-walk', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'mouse-anim', frame: '__grey_mouse_run_000.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_001.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_002.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_003.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_004.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_005.png' },
                    { key: 'mouse-anim', frame: '__grey_mouse_run_006.png' },
                ],
                frameRate: 10,
                repeat:-1 // -1 to loop forever
            });

            this.anims.create({
                key: 'worm-walk', // name of this animation
                // helper to generate frames
                frames: [
                    { key: 'worm-anim', frame: 'Monster4_01.png' },
                    { key: 'worm-anim', frame: 'Monster4_02.png' },
                    { key: 'worm-anim', frame: 'Monster4_03.png' },
                    { key: 'worm-anim', frame: 'Monster4_04.png' },
                ],
                frameRate: 10,
                repeat:-1 // -1 to loop forever
            });

            this.mouse[0] = this.add.sprite(
                this.mouseHole[0].x, // x value
                mouseYPos, // y value
                TextureKeys.Mouse
            ).setFlipX(true);

            this.mouse[1] = this.add.sprite(
                this.mouseHole[1].x, // x value
                mouseYPos, // y value
                TextureKeys.Mouse
            ).setFlipX(true);

            this.mouse[0].setOrigin(0.5, 1).setScale(0.2);
            this.mouse[1].setOrigin(0.5, 1).setScale(0.2);

            this.physics.add.existing(this.mouse[0]);
            this.physics.add.existing(this.mouse[1]);
            (this.mouse[0].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
            (this.mouse[1].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
            this.mouse[0].setActive(false);
            this.mouse[0].setVisible(false);
            this.mouse[1].setActive(false);
            this.mouse[1].setVisible(false);
            this.mouse[0].setDepth(25);
            this.mouse[1].setDepth(25); 

            // 
            const wormY = 640;

            this.worm[0] = this.add.sprite(
                this.mouseHole[0].x, // x value
                wormY, // y value
                TextureKeys.Worm
            );

            this.worm[1] = this.add.sprite(
                this.mouseHole[1].x, // x value
                wormY, // y value
                TextureKeys.Worm
            );

            this.worm[0].setOrigin(0.5, 1).setScale(0.3);
            this.worm[1].setOrigin(0.5, 1).setScale(0.3);

            this.physics.add.existing(this.worm[0]);
            this.physics.add.existing(this.worm[1]);
            (this.worm[0].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
            (this.worm[1].body as Phaser.Physics.Arcade.Body).setCollideWorldBounds(true);
            this.worm[0].setActive(false);
            this.worm[0].setVisible(false);
            this.worm[1].setActive(false);
            this.worm[1].setVisible(false);
            this.worm[0].setDepth(25);
            this.worm[1].setDepth(25);

            this.tv = this.add.sprite(
                tvX, // x value
                tvY, // y value
                TextureKeys.TV
            );

            this.tv.setScale(0.65);
            this.tv.setDepth(12);
            this.tv.setOrigin(0.5, 1);
            
            this.tvShelve = this.add.sprite(
                tvShelveX, // x value
                tvShelveY, // y value
                TextureKeys.SHELVE
            );

            this.tvShelve.setScale(0.5 ,0.3);
            this.tvShelve.setDepth(12);
            this.tvShelve.setOrigin(0.5, 1);

            console.log("load complete event end");
        })

        this.load.start();


        console.log("preload 2 end");
        
      }

      private samplePosition(time: number) {
        const scrollX = this.cameras.main.scrollX
        var rightEdge = scrollX + this.scale.width

        if (this.positionSampling < time)
        {
            var pos: integer = Math.round(this.positionSampling/GameOptions.speedSampling);
            if (rightEdge > GameOptions.RaceDistance * 100) {
                rightEdge = GameOptions.RaceDistance * 100;
            }

            this.currPlayerPositionList.push(Math.round(rightEdge));
            console.log('************************************************************');
            console.log('pos- ' + pos + ' time' + this.currPlayerPositionList[pos]);
            console.log('sample - ' + this.currPlayerPositionList.toString());

            this.positionSampling = time + GameOptions.speedSampling;
        }
      }

      private calcShadowPosition(time: number) {
        const scrollX = this.cameras.main.scrollX
        var rightEdge = scrollX + this.scale.width

        if (this.positionComp < time)
        {
            var pos: number = Math.round((this.positionComp - GameOptions.speedSampling)/GameOptions.speedSampling);
            
            console.log('comparing pos- ' + rightEdge + ' time' + time);

            if (rightEdge > GameOptions.RaceDistance * 100) {
                rightEdge = GameOptions.RaceDistance * 100;
            }

            var topPlayerPosition = this.topPlayerPositionList[pos];
            if (topPlayerPosition == undefined) {
                topPlayerPosition = this.topPlayerPositionList[this.topPlayerPositionList.length-1];
            }

            if (topPlayerPosition >=  rightEdge) {
                console.log('curr position is slower, record - ' + topPlayerPosition + ' curr' + rightEdge);
                this.shadowTargetPos = this.scale.width;
            }
            else if (topPlayerPosition >  rightEdge - 200) {
                console.log('curr position is slower, record - ' + topPlayerPosition + ' curr' + rightEdge);
                this.shadowTargetPos = 150;
            } else {
                console.log('curr position is faster, record - ' + topPlayerPosition + ' curr' + rightEdge);
                this.shadowTargetPos = 50;
            }

            this.positionComp = time + GameOptions.speedSampling;
        }

        this.updateShadowPosition(time);
      }

      private updateShadowPosition(time)
      {
        const scrollX = this.cameras.main.scrollX
        const rightEdge = scrollX + this.scale.width

        // console.log('player pos - ' + this.shadowPlayer.getPosX() + ' target pos' + this.shadowTargetPos);
        if (this.shadowPlayer.getPosX() < this.shadowTargetPos - 50 && this.shadowRunningTimer < time) {
            console.log('moving time is', time);
            console.log('shadow pos is', this.shadowPlayer.getPosX());
            console.log('edge pos is', rightEdge);
            this.shadowRunningTimer = time + 20;

            const body = this.Player.body as Phaser.Physics.Arcade.Body;
            if (body.velocity.x > 100) {
                this.shadowPlayer.moveForward(4);
            } else {
                this.shadowPlayer.moveForward(10);
            }
        }

        if (this.shadowPlayer.getPosX() > this.shadowTargetPos + 50 && this.shadowRunningTimer < time) {
            console.log('moving time is', time);
            console.log('shadow pos is', this.shadowPlayer.getPosX());
            console.log('edge pos is', rightEdge);
            this.shadowRunningTimer = time + 20;
            this.shadowPlayer.moveForward(-4);
        }

      }

      private emitSnow() {
        const particles = this.add.particles(TextureKeys.SNOW);
        particles.setDepth(1000);
		particles.createEmitter({
			x: 0,
			y: 0,
			// emitZone
			emitZone: {
				source: new Phaser.Geom.Rectangle(-this.scale.width * 3, 0, this.scale.width * 7, 100),
				type: 'random',
				quantity: 10
			},
			speedY: { min: 50, max: 70 },
			speedX: { min: -20, max: 20 },
			accelerationY: { random: [10, 15] },
			// lifespan
			lifespan: { min: 5000, max: 7000 },
			scale: { random: [0.025, 0.075] },
			alpha: { random: [0.1, 0.8] },
			gravityY: 10,
			frequency: 10,
			blendMode: 'ADD',
			// follow the player at an offiset
			follow: this.Player, 
			followOffset: { x: -this.scale.width * 0.5, y: -this.scale.height - 100 }
		})
      }
}