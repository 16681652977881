import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, take } from 'rxjs/operators';

declare function decodeURI(any: any):any; 

export interface Resources {
    blobs: string[];
    gameName: string;
    gemsText: string;
    scoreMessageText: string;
    gameEndText: string;
    chosenCharacter: string;
    gameRecord: string;
  }

  @Injectable({
    providedIn: 'root',
  })
  export class ResourcesService {

  constructor(private http: HttpClient) { }

  customImagesList: string[] = [];
  gameName: string;
  gemsText: string;
  scoreMessageText: string;
  gameEndText: string;
  snapshot: string;
  chosenCharacter: string;
  gameRecord: string;
  gameMode: string = 'RegularMode';
  playerName: string;

  // configUrl = 'https://gammatafunction20210910155026.azurewebsites.net/api/GetGameParams?code=MwOHNXyF/iTOOyf5AQ9h9oy1wnqolxGiC9MjNY10Vq/Y4rplnNnOgQ==&gameid=';
  configUrl = 'https://charmifunc20240210224026.azurewebsites.net/api/GetGameParams?code=xRABenggTNmG2aTFB1WIv6GjGXazs_VduyjIPCh6KnStAzFum0WR1A==&gameid=';
  // updateRecordUrl = 'https://gammatafunction20210910155026.azurewebsites.net/api/SendRecordLog?code=Fe6Xu3e073Is3SU43xHnnVfVxcPGBpl8lwa9TLC1v524AzFulLehAQ==&gameid=';
  updateRecordUrl = 'https://charmifunc20240210224026.azurewebsites.net/api/SendRecordLog?code=duDXricKWwEmJEAodVT4dJFlpFFrIukrpaR3uJc31ZUoAzFulOw-Sw==&gameid=';  

  getResources(configId: string): Promise<Resources>{
    var gameId = '';
    if (!!configId)
    {
      var gameId = configId.toString();
    }
    
    return new Promise(resolve=>{
      this.http.get<Resources>(this.configUrl + gameId).pipe(
             take(1)
           )
           .subscribe(
              (data:any) => {
                  console.log(data);
                  resolve(data);
           })
      })
  }

  updateRecord(gameId: string, recordLog: string): Promise<void>{
    return new Promise(resolve=>{
      this.http.post(this.updateRecordUrl + gameId, recordLog).pipe(
             take(1)
           )
           .subscribe(
              () => {
                resolve();
           })
      })
  }


  getResourcesV0(configId: string): Observable<Resources>{
    var gameId = '';
    if (!!configId)
    {
      var gameId = configId.toString();
    }
    
    return  this.http.get<Resources>(this.configUrl + gameId);
  }


  getQueryString  (parameter: any) {

    if (parameter === undefined) { parameter = ''; }

    var output: any;
    var keyValues = location.search.substring(1).split('&');

    for (var i in keyValues)
    {
        var key = keyValues[i].split('=');

        if (key[0] == parameter)
        {
          return key[1];
        }
    }

    return output;
  }

  setGameName(gameName: string) {

    this.gameName = gameName;
  }

  getGameName(): string {

    return this.gameName;
  }

  setGemsText(gemsText: string) {

    this.gemsText = gemsText;
  }

  getGemsText(): string {

    return this.gemsText;
  }

  setScoreMessageText(scoreMessageText: string) {

    this.scoreMessageText = scoreMessageText;
  }

  getScoreMessageText(): string {

    return this.scoreMessageText;
  }

  setCustomImagesList(list: string[]) {

    this.customImagesList = list;
  }

  getCustomImagesList(): string[] {

    return this.customImagesList;
  }

  setGameEndText(gameEndText: string) {

    this.gameEndText = gameEndText;
  }

  getGameEndText(): string {

    return this.gameEndText;
  }

  setSnapshot(snapshot: string) {

    this.snapshot = snapshot;
  }

  getSnapshot(): string {

    return this.snapshot;
  }

  getCharacterType(): string {

    return this.chosenCharacter;
  }

  setChosenCharacter(chosenCharacter: string) {
    this.chosenCharacter = chosenCharacter;
  }

  setGameMode(gameMode: string) {
    this.gameMode = gameMode;
  }

  getGameMode(): string {
    return this.gameMode;
  }

  setGameRecord(gameRecord: string) {
    this.gameRecord = gameRecord;
  }

  getGameRecord(): string {
    return this.gameRecord;
  }

  setPlayerName(playerName: string) {
    this.playerName = playerName;
  }

  getPlayerName(): string {
    return this.playerName;
  }
}